import { CustomIcon, IconNames } from '@vgw/multibrand-fe-icon'
import { cn } from '@vgw/tailwind-merger'
import { NavLink } from 'react-router-dom'

interface SiteNavItemProps {
  hasNotifications: boolean
  to: string
  iconName: IconNames
  className?: string
  label: string
}

export const SiteNavItem = ({
  label,
  to,
  iconName,
  hasNotifications,
  className = '',
  ...props
}: SiteNavItemProps) => {
  return (
    <NavLink
      to={to}
      className={cn(
        [
          'relative',
          'flex items-center',
          'gap-3 p-3',
          'bg-side-menu-button-default',
          'hover:bg-side-menu-button-hover',
          'active:bg-side-menu-button-active',
        ],
        className,
      )}
      {...props}
    >
      {hasNotifications && (
        <span className="absolute left-[2px] h-[10px] w-[10px] rounded-full bg-red-600" />
      )}

      {!!iconName && (
        <CustomIcon
          name={iconName}
          size="md"
          className="inline-block shrink-0"
        />
      )}
      <span className="font-display text-lg font-normal leading-[26px] text-content-base">
        {label}
      </span>
    </NavLink>
  )
}
