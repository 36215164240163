import { cva, type VariantProps } from 'class-variance-authority'
import { LabelHTMLAttributes, useContext } from 'react'
import { cn } from '@vgw/tailwind-merger'
import { FormSectionContext } from './form-section.context'

const labelVariants = cva('text-content-base font-normal', {
  variants: {
    size: {
      sm: 'text-sm leading-5',
      md: 'text-base leading-6',
    },
  },
  defaultVariants: {
    size: 'sm',
  },
})

export interface OptionalLabelProps
  extends LabelHTMLAttributes<HTMLLabelElement>,
    VariantProps<typeof labelVariants> {
  text?: string
}

const OptionalLabel = ({
  className,
  text = '(optional)',
  ...props
}: OptionalLabelProps) => {
  const { size } = useContext(FormSectionContext)

  return (
    <label className={cn(labelVariants({ size, className }))} {...props}>
      {text}
    </label>
  )
}

OptionalLabel.displayName = 'OptionalLabel'

export { OptionalLabel }
