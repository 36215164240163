import { useEffect, useState } from 'react'
import {
  Root,
  SelectProps as PrimitiveSelectProps,
} from '@radix-ui/react-select'
import { SelectSize } from './types'
import { SelectContext } from './select.context'

export interface SelectProps extends PrimitiveSelectProps {
  size?: SelectSize
  error?: boolean
  showValue?: boolean
  placeholder?: string
  hasLabel?: boolean
}

const Select = ({
  size = 'sm',
  value: initialValue,
  error = false,
  disabled = false,
  hasLabel = true,
  showValue = true,
  placeholder = '--Select--',
  open,
  onValueChange,
  ...props
}: SelectProps) => {
  const [value, setValue] = useState(initialValue)
  const [focused, setFocused] = useState(open)

  useEffect(() => {
    setValue(initialValue)
  }, [initialValue])

  return (
    <SelectContext.Provider
      value={{
        size,
        error,
        onSetValue: setValue,
        value,
        hasLabel,
        showValue,
        disabled,
        placeholder,
        focused: focused || false,
        onSetFocused: setFocused,
      }}
    >
      <Root
        value={value}
        disabled={disabled}
        open={open}
        onOpenChange={setFocused}
        onValueChange={(value) => {
          if (onValueChange) {
            onValueChange(value)
          }
          if (setValue) {
            setValue(value)
          }
        }}
        {...props}
      />
    </SelectContext.Provider>
  )
}

Select.displayName = 'Select'

export { Select }
