import { HTMLAttributes } from 'react'
import { cn } from '@vgw/tailwind-merger'

type CardProps = {
  index: number
} & HTMLAttributes<HTMLDivElement>

const Card = ({ index, children, className, ...rest }: CardProps) => (
  <div className={cn('min-w-0 flex-none', className)} key={index} {...rest}>
    {children}
  </div>
)

Card.displayName = 'Carousel.Card'

export { Card }
