import { useQuery } from '@tanstack/react-query'
import { getBrandConfig } from '@/config/config'
import { GalleryResponse, getGalleryViewOptions } from '@/apis/gap-gallery-view'

import { useSelectedCoinType } from '@/use-selected-coin-type'

type GameCategoryLayout = 'SQUARE' | 'PORTRAIT' | 'LANDSCAPE'

export type GameGalleryResponse = GalleryResponse

const mapper = (res: GameGalleryResponse) => ({
  coinMode: res.coinMode,
  categories: res.categories.map((category) => ({
    name: category.name,
    index: category.index,
    variant:
      category.layout.toLocaleLowerCase() as Lowercase<GameCategoryLayout>,
    iconUrl: category.iconUrl,
    description: category.description,
    games: category.games,
  })),
})

export type GameCategoriesQueryData = ReturnType<typeof mapper>
export type GameCategory = GameCategoriesQueryData['categories'][number]
export type Game = GameCategory['games'][number]

function useGalleryQuery() {
  const config = getBrandConfig()
  const selectedCoinType = useSelectedCoinType()

  const { data, isLoading, error } = useQuery({
    ...getGalleryViewOptions({
      query: {
        coinType: selectedCoinType,
      },
      path: {
        brandCode: config.code,
      },
    }),
  })

  return {
    isLoading: isLoading,
    data: data ? mapper(data) : undefined,
    error,
  }
}

export { useGalleryQuery }
