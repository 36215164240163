import { Close as PrimitiveClose } from '@radix-ui/react-dialog'
import { useLingui } from '@lingui/react/macro'
import { IconButton } from '@vgw/multibrand-fe-icon-button'

const Close = () => {
  const { t } = useLingui()

  return (
    <PrimitiveClose asChild>
      <IconButton
        className="absolute right-1 top-1"
        aria-label={t`Close`}
        icon="cross-1"
        variant="ghost"
      />
    </PrimitiveClose>
  )
}

Close.displayName = 'DialogClose'

export { Close }
