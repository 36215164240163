import { Carousel } from '@vgw/multibrand-fe-carousel'
import { useCarousel, WheelGesturesPlugin } from '@vgw/multibrand-fe-carousel'
import { Trans } from '@lingui/react/macro'

interface MoreGamesProps {
  games: {
    gameId: string
    imageUrl: string
    name: string
  }[]
}

export const MoreGames = (props: MoreGamesProps) => {
  const [emblaRef] = useCarousel({}, [WheelGesturesPlugin()])

  return (
    <div className="flex flex-col items-start gap-2 self-stretch">
      <div className="text-sm leading-5">
        <Trans>More games like this</Trans>
      </div>
      <Carousel>
        <Carousel.Container ref={emblaRef}>
          <Carousel.CardContainer className="flex items-start gap-2 self-stretch">
            {props.games.map((game, index) => (
              <Carousel.Card key={game.gameId} index={index}>
                <img
                  src={game.imageUrl}
                  className="h-20 w-20 shrink-0 rounded bg-gray-700"
                  alt={game.name}
                />
              </Carousel.Card>
            ))}
          </Carousel.CardContainer>
        </Carousel.Container>
      </Carousel>
    </div>
  )
}
