import { FC, ReactNode } from 'react'
import { GameCategory } from '@/features/game-api/use-gallery-query'
import {
  Carousel,
  useCarousel,
  WheelGesturesPlugin,
} from '@vgw/multibrand-fe-carousel'
import { CategoryIcon } from './category-icon'
import { DescriptionPopover } from './description-popover'
import { ViewAll } from './view-all'

interface CategoryProps
  extends Pick<GameCategory, 'name' | 'description' | 'iconUrl'> {
  layout?: ReactNode
}

const Category: FC<CategoryProps> = ({
  name,
  layout,
  description,
  iconUrl,
}) => {
  const [emblaRef] = useCarousel(
    { loop: true, align: 'start', dragFree: true },
    [WheelGesturesPlugin()],
  )

  return (
    <section data-testid={`category-${name}`} className="flex flex-col gap-2">
      <div className="flex items-center gap-3 md:justify-start">
        {!!iconUrl && (
          <CategoryIcon title={name} iconUrl={iconUrl} className="shrink-0" />
        )}
        <div className="flex min-h-9 items-center gap-2 overflow-hidden">
          <h2 className="overflow-hidden text-ellipsis font-display text-xl font-bold text-category-title md:text-2xl">
            {name}
          </h2>
          {!!description && (
            <DescriptionPopover
              accessibilityLabel={`${name} description`}
              content={description}
            />
          )}
        </div>
        <ViewAll categorySlug={name} className="ml-auto shrink-0 md:ml-0" />
      </div>
      <Carousel>
        <Carousel.Container ref={emblaRef}>
          <Carousel.CardContainer>{layout}</Carousel.CardContainer>
        </Carousel.Container>
      </Carousel>
    </section>
  )
}

export { Category }
