import { useContext } from 'react'
import { cva } from 'class-variance-authority'
import { Icon, SelectIconProps } from '@radix-ui/react-select'
import { cn } from '@vgw/tailwind-merger'
import { CustomIcon } from '@vgw/multibrand-fe-icon'
import { SelectContext } from './select.context'

const selectIconVariants = cva('', {
  variants: {
    size: {
      sm: 'mt-2',
      md: 'mt-3',
    },
  },
  defaultVariants: {
    size: 'sm',
  },
})

const SelectIcon = ({ className, ...props }: SelectIconProps) => {
  const { size } = useContext(SelectContext)

  return (
    <Icon className={cn(selectIconVariants({ size, className }))} {...props}>
      <CustomIcon name="chevron-down" size={size} />
    </Icon>
  )
}

SelectIcon.displayName = 'SelectIcon'

export { SelectIcon }
