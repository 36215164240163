import { cn } from '@vgw/tailwind-merger'
import InputMask, { Props as MaskProps } from 'react-input-mask'
import { forwardRef } from 'react'
import { useTextFieldContext } from '../text-field.context'

export interface InputProps extends MaskProps {}

const MaskedInput = forwardRef<HTMLInputElement, InputProps>(
  ({ className, ...props }, ref) => {
    const {
      setFocused,
      setHasValue,
      value,
      defaultValue,
      error,
      size,
      disabled,
    } = useTextFieldContext()

    const handleFocus = () => {
      setFocused(true)
    }

    const handleBlur = (e: React.FocusEvent<HTMLInputElement>) => {
      setFocused(false)
      setHasValue(!!e.target.value)
    }

    return (
      <InputMask
        inputRef={ref}
        className={cn(
          'w-full bg-transparent pb-1 outline-none read-only:cursor-auto',
          size === 'sm' && 'pt-3 text-sm font-normal leading-5',
          size === 'md' && 'pt-4 text-base font-normal leading-6',
          error && 'text-status-error',
          disabled && 'pointer-event-none',
          className,
        )}
        onFocus={handleFocus}
        onBlur={handleBlur}
        value={value}
        defaultValue={defaultValue}
        disabled={disabled}
        {...props}
      />
    )
  },
)

MaskedInput.displayName = 'TextField.Input'

export { MaskedInput }
