import { useState } from 'react'
import { cn } from '@vgw/tailwind-merger'

interface CategoryIconProps {
  title: string
  iconUrl: string
  className?: string
}

export const CategoryIcon = ({
  title,
  iconUrl,
  className,
}: CategoryIconProps) => {
  const [isIconImageLoaded, setIsIconImageLoaded] = useState(false)

  return (
    <div
      className={cn(
        [
          'h-5',
          'w-5',
          'md:h-6',
          'md:w-6',
          'lg:h-7',
          'lg:w-7',
          'transition',
          'duration-300',
        ],
        {
          'bg-gray-100': !isIconImageLoaded,
        },
        className,
      )}
    >
      <img
        src={iconUrl}
        alt={title}
        title={title}
        className={cn(
          ['h-full', 'w-full', 'object-cover', 'transition', 'duration-300'],
          {
            'opacity-0': !isIconImageLoaded,
          },
        )}
        onLoad={() => setIsIconImageLoaded(true)}
        aria-hidden
      />
    </div>
  )
}
