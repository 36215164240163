import { forwardRef, HTMLAttributes } from 'react'
import { cn } from '@vgw/tailwind-merger'

type CardContainerProps = HTMLAttributes<HTMLDivElement>

const CardContainer = forwardRef<HTMLDivElement, CardContainerProps>(
  ({ className, ...rest }, ref) => (
    <div
      className={cn('flex touch-pan-y touch-pinch-zoom', className)}
      style={{
        backfaceVisibility: 'hidden',
        marginLeft: 'calc(var(--slide-spacing) * -1)',
      }}
      ref={ref}
      {...rest}
    />
  ),
)

CardContainer.displayName = 'CardContainer'

export { CardContainer }
