import { useState, useEffect } from 'react'

type ScrollDirection = null | 'down' | 'up'

/**
 * Custom React hook to detect the scroll direction of the window.
 * Returns either `"down"`, `"up"`, or `null` based on the user's scroll behavior.
 *
 * @returns {("down" | "up" | null)} - The current scroll direction:
 * - `"down"` when scrolling down,
 * - `"up"` when scrolling up,
 * - `null` if the direction hasn't been set yet.
 */
export const useScrollDirection = (): ScrollDirection => {
  const [scrollDirection, setScrollDirection] = useState<ScrollDirection>(null)

  useEffect(() => {
    let lastScrollY = window.scrollY

    const updateScrollDirection = () => {
      const scrollY = window.scrollY
      const direction = scrollY > lastScrollY ? 'down' : 'up'
      if (
        direction !== scrollDirection &&
        (scrollY - lastScrollY > 1 || scrollY - lastScrollY < -1)
      ) {
        setScrollDirection(direction)
      }
      lastScrollY = scrollY > 0 ? scrollY : 0
    }

    window.addEventListener('scroll', updateScrollDirection)
    return () => {
      window.removeEventListener('scroll', updateScrollDirection)
    }
  }, [scrollDirection])

  return scrollDirection
}
