import { useQuery } from '@tanstack/react-query'
import { getBrandConfig } from '@/config/config'
import {
  getTickersOptions,
  JackpotResponse,
  GetTickersResponse,
} from '@/apis/gap-tickers'

export type Jackpot = JackpotResponse
export type Jackpots = GetTickersResponse

function useJackpotsQuery() {
  const config = getBrandConfig()

  const { error, data, isLoading } = useQuery({
    ...getTickersOptions({
      path: {
        brand: config.code,
      },
    }),

    refetchInterval: 5000,
  })

  return {
    isLoading,
    error,
    data,
  }
}

export { useJackpotsQuery }
