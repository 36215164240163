import { cva, type VariantProps } from 'class-variance-authority'
import { LabelHTMLAttributes, useContext } from 'react'
import { cn } from '@vgw/tailwind-merger'
import { FormSectionContext } from './form-section.context'

const labelVariants = cva('text-content-base flex gap-1 font-bold', {
  variants: {
    size: {
      sm: 'text-sm leading-5',
      md: 'text-base leading-6',
    },
  },
  defaultVariants: {
    size: 'sm',
  },
})

export interface LabelProps
  extends LabelHTMLAttributes<HTMLLabelElement>,
    VariantProps<typeof labelVariants> {
  htmlFor: string
}

const Label = ({ className, htmlFor, ...props }: LabelProps) => {
  const { size } = useContext(FormSectionContext)

  return (
    <label
      htmlFor={htmlFor}
      className={cn(labelVariants({ size, className }))}
      {...props}
    />
  )
}

Label.displayName = 'Label'

export { Label }
