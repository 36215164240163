import { useCallback, useState } from 'react'

export interface UseToggleProps {
  initial?: boolean
}

export const useToggle = ({ initial = false }: UseToggleProps = {}) => {
  const [state, setState] = useState(initial)

  return {
    state,
    on: useCallback(() => setState(true), []),
    off: useCallback(() => setState(false), []),
    toggle: useCallback(() => setState((prev) => !prev), []),
  }
}
