import { useContext } from 'react'
import { cva } from 'class-variance-authority'
import { cn } from '@vgw/tailwind-merger'
import { SelectContext } from './select.context'

const selectLabelVariants = cva(
  cn([
    'text-content-placeholder',

    'origin-bottom-left',
    'transition-all',
    'duration-300',

    'pointer-events-none',
  ]),
  {
    variants: {
      size: {
        sm: [
          'text-[.625rem]',
          'leading-[.875rem]',
          'translate-y-[.8rem]',
          'scale-[1.4]',
        ],
        md: ['text-xs', 'translate-y-[.875rem]', 'scale-[1.375]'],
      },
    },
    defaultVariants: {
      size: 'sm',
    },
  },
)

const SelectLabel = () => {
  const {
    focused = false,
    value,
    disabled = false,
    error = false,
    size,
    placeholder,
    hasLabel,
  } = useContext(SelectContext)

  const hasValue = !!value

  if (!hasLabel && (focused || value)) {
    return null
  }

  return (
    <span
      className={cn(selectLabelVariants({ size }), {
        'text-form-input-content-label-active': focused && !error,
        'group-hover:text-form-input-content-label-active': !error,
        'group-active:text-form-input-content-label-active': !error,

        'text-status-error': error,
        'text-content-disabled': disabled,
        'translate-y-0 scale-100': focused || hasValue,
      })}
    >
      {placeholder}
    </span>
  )
}

SelectLabel.displayName = 'SelectLabel'

export { SelectLabel }
