import { useOidc } from '@/features/oidc/oidc'

export interface GetOptions {
  queryParams?: Record<string, string>
}

function useFetch() {
  const { isUserLoggedIn, oidcTokens } = useOidc()

  return {
    get: async <T>(
      path: string,
      { queryParams }: GetOptions | undefined = {},
    ): Promise<T> => {
      const response = await fetch(
        queryParams ? `${path}?${new URLSearchParams(queryParams)}` : path,
        {
          headers: getHeaders(isUserLoggedIn, oidcTokens?.accessToken),
        },
      )

      if (!response.ok) {
        throw new Error('Response was not ok')
      }

      return response.json()
    },
    post: async (path: string) => {
      const response = await fetch(path, {
        headers: getHeaders(isUserLoggedIn, oidcTokens?.accessToken),
        method: 'POST',
      })

      if (!response.ok) {
        throw new Error('Response was not ok')
      }

      return response.json()
    },
  }
}

function getHeaders(authenticate: boolean, authToken?: string): HeadersInit {
  if (!authenticate) return {}

  return { Authorization: `Bearer ${authToken}` }
}

export { useFetch }
