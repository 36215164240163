import { forwardRef, useContext } from 'react'
import { cva, VariantProps } from 'class-variance-authority'
import {
  Item,
  SelectItemProps as PrimitiveSelectItemProps,
} from '@radix-ui/react-select'
import { cn } from '@vgw/tailwind-merger'
import { SelectContext } from './select.context'

const selectItemVariants = cva(
  [
    'text-content-base',
    'flex',
    'w-full',
    'items-center',
    'gap-1',
    'p-1.5',
    'px-3',
    'cursor-pointer',
    'outline-none',

    'odd:bg-form-input-surface',
    'even:bg-surface-base',
    'hover:bg-brand-secondary',
    'data-[highlighted]:bg-brand-secondary',
    'data-[state=checked]:bg-brand-secondary',
  ],
  {
    variants: {
      size: {
        sm: ['py-1.5', 'text-sm', 'leading-5'],
        md: ['py-2', 'text-base'],
      },
    },
    defaultVariants: {
      size: 'sm',
    },
  },
)

export interface SelectItemProps
  extends VariantProps<typeof selectItemVariants>,
    PrimitiveSelectItemProps {}

const SelectItem = forwardRef<HTMLDivElement, SelectItemProps>(
  ({ children, className, ...props }, ref) => {
    const { size } = useContext(SelectContext)

    return (
      <Item
        className={cn(selectItemVariants({ size, className }))}
        ref={ref}
        {...props}
      >
        {children}
      </Item>
    )
  },
)

SelectItem.displayName = 'SelectItem'

export { SelectItem }
