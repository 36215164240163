import { ComponentPropsWithoutRef, forwardRef, useContext } from 'react'
import { cva } from 'class-variance-authority'
import { cn } from '@vgw/tailwind-merger'
import { SelectContext } from './select.context'

const selectInputVariants = cva(
  cn([
    'w-full',
    'bg-transparent',
    'text-left',
    'font-normal',
    'outline-none',
    'read-only:cursor-auto',
  ]),
  {
    variants: {
      size: {
        sm: ['text-sm leading-5'],
        md: ['text-base leading-6'],
      },
    },
    defaultVariants: {
      size: 'sm',
    },
  },
)

interface SelectInputProps extends ComponentPropsWithoutRef<'div'> {}

const SelectInput = forwardRef<HTMLInputElement, SelectInputProps>(
  ({ className, children, ...props }, ref) => {
    const { size, hasLabel } = useContext(SelectContext)

    if (!children) {
      return null
    }

    return (
      <div
        ref={ref}
        className={cn(selectInputVariants({ size, className }), {
          'flex h-full items-center': !hasLabel,
        })}
        {...props}
      >
        {children}
      </div>
    )
  },
)

SelectInput.displayName = 'SelectInput'

export { SelectInput }
