import { ButtonHTMLAttributes } from 'react'
import { cn } from '@vgw/tailwind-merger'
import { IconButton, IconButtonProps } from '@vgw/multibrand-fe-icon-button'
import { IconNames } from '@vgw/multibrand-fe-icon'

type ArrowProps = {
  arrow: 'left' | 'right'
  size?: IconButtonProps['size']
} & ButtonHTMLAttributes<HTMLButtonElement>

const Arrow = ({ className, arrow, size = 'md', ...rest }: ArrowProps) => {
  let arrowIcon: IconNames = 'chevron-right'
  switch (arrow) {
    case 'left':
      arrowIcon = 'chevron-left'
      break
    case 'right':
      arrowIcon = 'chevron-right'
      break
  }
  return (
    <IconButton
      icon={arrowIcon}
      size={size}
      variant="outline"
      className={cn(className)}
      {...rest}
    />
  )
}

export { Arrow }
