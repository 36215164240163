import { forwardRef, ReactNode } from 'react'
import {
  Content,
  List,
  Root,
  Trigger,
  TabsProps as PrimitiveTabsProps,
} from '@radix-ui/react-tabs'

export interface Tab {
  label: ReactNode
  value: string
  content: ReactNode
}

export interface TabsProps extends PrimitiveTabsProps {
  items: Tab[]
  className?: string
  defaultValue: string
  hideContent?: boolean
}

const Tabs = forwardRef<HTMLDivElement, TabsProps>(
  ({ items, hideContent, ...props }, ref) => (
    <Root {...props} ref={ref}>
      <List className="bg-store-tab-surface shadow-tabs rounded-radius-store-tab-surface flex p-1">
        {items.map((item) => (
          <Trigger
            className="text-store-tab-content font-family data-[state=active]:rounded-radius-store-tab-active data-[state=active]:text-store-tab-content-active data-[state=active]:shadow-tab data-[state=active]:bg-store-tab-surface-active flex-1 p-2 font-bold"
            key={item.value}
            value={item.value}
          >
            {item.label}
          </Trigger>
        ))}
      </List>
      {!hideContent &&
        items.map((item) => (
          <Content key={item.value} value={item.value}>
            {item.content}
          </Content>
        ))}
    </Root>
  ),
)

Tabs.displayName = Root.displayName

export { Tabs }
