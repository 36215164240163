import { I18n } from '@lingui/core'
import { msg } from '@lingui/core/macro'
import { useLingui, Trans } from '@lingui/react/macro'
import {
  Dialog,
  Overlay,
  Content,
  Close,
  Header,
  Title,
  Body,
} from '@vgw/multibrand-fe-dialog'
import { Tabs, Tab } from '@vgw/multibrand-fe-tabs'
import { useMemo, useState } from 'react'
import { useParams } from 'react-router-dom'
import { Button } from '@vgw/multibrand-fe-button'
import { RegularOfferItemsSet } from '@/features/store/regular-offer-items-set/regular-offer-items-set'
import {
  PlayerSpecialOfferResponse,
  RegularItemResponse,
} from '@/apis/pam-store-api'
import { loadBrandConfig } from '@/config/config'
import { SpecialOfferItemsSet } from '@/features/store/special-offer-items-set'

const config = await loadBrandConfig()

const TabValueMap = {
  'play-for-free': 'play-for-free',
  'buy-gold-coins': 'buy-gold-coins',
}

const getTabs = (i18n: I18n): Tab[] => [
  {
    label: i18n._(msg`Play for FREE`),
    value: TabValueMap['play-for-free'],
    content: '',
  },
  {
    label: i18n._(msg`Buy Gold Coins`),
    value: TabValueMap['buy-gold-coins'],
    content: '',
  },
]

type TabValue = keyof typeof TabValueMap

const getDefaultTab = (tab: TabValue | undefined) => {
  if (!tab) return TabValueMap['buy-gold-coins']
  return TabValueMap[tab] || TabValueMap['buy-gold-coins']
}

export const Store = ({
  regularStoreItems,
  specialOfferSets,
  isStoreOpen,
  setIsStoreOpen,
}: {
  regularStoreItems: RegularItemResponse[]
  specialOfferSets: PlayerSpecialOfferResponse[]
  isStoreOpen: boolean
  setIsStoreOpen: (isOpen: boolean) => void
}) => {
  const { tab } = useParams<{ tab: TabValue }>()
  const { i18n } = useLingui()
  const tabs = useMemo(() => getTabs(i18n), [i18n])
  const defaultTabValue = getDefaultTab(tab)
  const [currentTab, setCurrentTab] = useState<TabValue>(
    tab ?? 'buy-gold-coins',
  )

  return (
    <Dialog open={isStoreOpen} onOpenChange={setIsStoreOpen}>
      <Overlay />
      <Content
        className="md:w-[480px] lg:w-[512px] xl:w-[512px]"
        isFullScreenOnMobile
      >
        <Close />
        <Header className="!gap-2 !px-2 !py-3">
          <Title className="text-center font-display text-2xl font-bold leading-9 text-content-base">
            <Trans>Get Coins</Trans>
          </Title>
          <Tabs
            className="md:mx-2 lg:mx-4 xl:mx-6"
            items={tabs}
            defaultValue={defaultTabValue}
            onValueChange={(value: string) => setCurrentTab(value as TabValue)}
            hideContent
          />
        </Header>
        <Body className="!sm:px-4 !lg:px-6 !xl:px-6 xs:!px-2 mb-3">
          {currentTab === 'buy-gold-coins' && (
            <div
              data-testid="buy-gold-coins-wrapper"
              className="mt-3 flex flex-col gap-6"
            >
              <div>
                <h2 className="font-display text-lg font-bold leading-7 text-content-base">
                  <Trans>
                    <span className="capitalize">
                      {config.name.toLowerCase()}
                    </span>{' '}
                    is always FREE to play
                  </Trans>
                </h2>
                <div className="flex items-center">
                  <span className="font-body text-[15px] leading-4 text-content-base">
                    <Trans>No purchase necessary.</Trans>
                  </span>
                  <Button onClick={() => {}} variant="secondary" size="sm">
                    <Trans>More info</Trans>
                  </Button>
                </div>
              </div>
              <div className="flex flex-col gap-6">
                {specialOfferSets.map((item) => (
                  <SpecialOfferItemsSet
                    key={item.id}
                    name={item.name}
                    validUntil={
                      item.validUntil ? new Date(item.validUntil) : undefined
                    }
                    claim={item.claim}
                    items={item.items}
                    onItemClick={() => {}}
                  />
                ))}
                <RegularOfferItemsSet items={regularStoreItems} />
              </div>
            </div>
          )}
          {currentTab === 'play-for-free' && (
            <div data-testid="play-for-free-wrapper">
              <Trans>Free</Trans>
            </div>
          )}
        </Body>
      </Content>
    </Dialog>
  )
}
