import { useMemo } from 'react'
import { cn } from '@vgw/tailwind-merger'
import {
  SimpleHeroBannerBase,
  SimpleHeroBannerBaseCard,
} from './simple-hero-banner-base'

export const SimpleHeroBannerSkeleton = () => {
  const slide: SimpleHeroBannerBaseCard = useMemo(
    () => ({
      MainElement: ({ className }) => (
        <div className={cn(className, 'bg-skeleton w-full animate-pulse')} />
      ),
    }),
    [],
  )

  return <SimpleHeroBannerBase card={slide} />
}
