import { ComponentProps, ElementRef, forwardRef } from 'react'
import { Overlay as PrimitiveOverlay } from '@radix-ui/react-dialog'
import { cn } from '@vgw/tailwind-merger'

const Overlay = forwardRef<
  ElementRef<typeof PrimitiveOverlay>,
  ComponentProps<typeof PrimitiveOverlay>
>(({ className, ...props }, ref) => (
  <PrimitiveOverlay
    className={cn(
      [
        'bg-overlay-dark',
        'data-[state=open]:animate-dialog-overlay-show',
        'fixed',
        'inset-0',
      ],
      className,
    )}
    data-testid="dialog-overlay"
    ref={ref}
    {...props}
  />
))

Overlay.displayName = 'DialogOverlay'

export { Overlay }
