import { HTMLAttributes } from 'react'
import { cn } from '@vgw/tailwind-merger'

type IndicatorProps = {
  isActive: boolean
} & HTMLAttributes<HTMLButtonElement>

const Indicator = ({ isActive, className, ...rest }: IndicatorProps) => (
  <button
    className={cn(isActive && 'pointer-events-none', 'p-1', className)}
    disabled={isActive}
    {...rest}
  >
    <svg
      width="10"
      height="10"
      viewBox="0 0 10 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle
        cx="5"
        cy="5"
        r="5"
        className={isActive ? 'fill-brand-primary' : 'fill-surface-muted'}
      />
    </svg>
  </button>
)

export { Indicator }
