import { useMemo } from 'react'
import { Jackpot } from '../game-tile'

const prority: Record<Jackpot['name'], number> = {
  grand: 1,
  major: 0,
  minor: -1,
}

export interface UseJackpotPrioritizationProps {
  jackpots: Jackpot[]
}

export const useJackpotPrioritization = ({
  jackpots,
}: UseJackpotPrioritizationProps) => {
  return useMemo(
    () =>
      [...jackpots]
        .filter(({ name }) => typeof prority[name] === 'number')
        .sort((a, b) => prority[b.name] - prority[a.name]),
    [jackpots],
  )
}
