import { HTMLAttributes } from 'react'
import { cn } from '@vgw/tailwind-merger'

type IndicatorsContainerProps = HTMLAttributes<HTMLDivElement>

const IndicatorsContainer = ({
  className,
  ...rest
}: IndicatorsContainerProps) => (
  <div
    className={cn('flex items-start justify-center gap-0.5', className)}
    {...rest}
  />
)

export { IndicatorsContainer }
