import { FormSection as FormSectionContainer } from './form-section'
import { HelperText } from './helper-text'
import { Label } from './label'
import { OptionalLabel } from './optional-label'
import { ErrorMessage } from './error-message'

export const FormSection = Object.assign(FormSectionContainer, {
  HelperText,
  ErrorMessage,
  Label,
  OptionalLabel,
})
