import smImageUrl from './multistack_gconly_sm.png'
import sm2xImageUrl from './multistack_gconly_sm@2x.png'

import mdImageUrl from './multistack_gconly_md.png'
import md2xImageUrl from './multistack_gconly_md@2x.png'

import lgImageUrl from './multistack_gconly_lg.png'
import lg2xImageUrl from './multistack_gconly_lg@2x.png'

export const goldCoinImageMap = {
  multistack_gconly_sm: {
    small: smImageUrl,
    medium: sm2xImageUrl,
  },
  multistack_gconly_md: {
    small: mdImageUrl,
    medium: md2xImageUrl,
  },
  multistack_gconly_lg: {
    small: lgImageUrl,
    medium: lg2xImageUrl,
  },
}
