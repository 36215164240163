import React from 'react'
import { cva, type VariantProps } from 'class-variance-authority'
import { Slot, Slottable } from '@radix-ui/react-slot'
import { cn } from '@vgw/tailwind-merger'
import { IconNames } from '@vgw/multibrand-fe-icon'
import { CustomIcon } from '@vgw/multibrand-fe-icon'
import { PulseAnimation } from '@vgw/multibrand-fe-pulse-animation'
import { useLingui } from '@lingui/react/macro'
import('./button.styles.css')

const buttonVariants = cva(
  [
    'relative',
    'flex',
    'items-center',
    'justify-center',
    'font-display',
    'font-medium',
    'text-base',
    'outline-offset-2',
    'focus:outline-button-border-focus',
    'disabled:bg-disabled',
    'disabled:text-content-disabled',
  ].join(' '),
  {
    variants: {
      variant: {
        primary: [
          'rounded-radius-button-brand-primary',
          'bg-button-surface-brand-primary',
          'text-button-content-brand-primary',
          'hover:bg-button-surface-brand-primary-hover',
          'active:bg-button-surface-brand-primary-active',
          'border-button-border-width-brand-primary',
          'border-button-border-brand-primary',
          'disabled:border-0',
        ].join(' '),
        secondary: [
          'rounded-radius-button-brand-secondary',
          'bg-button-surface-brand-secondary',
          'text-button-content-brand-secondary',
          'hover:bg-button-surface-brand-secondary-hover',
          'active:bg-button-surface-brand-secondary-active',
        ].join(' '),
        outline: [
          'rounded-radius-button-brand-primary',
          'border',
          'border-button-border-outline',
          'text-button-content-outline',
          'hover:bg-button-surface-ghost-hover',
          'active:bg-button-surface-ghost-active',
          'disabled:border-transparent',
        ].join(' '),
        ghost: [
          'rounded-radius-button-brand-secondary',
          'text-button-content-brand-secondary',
          'hover:bg-button-surface-ghost-hover',
          'active:bg-button-surface-ghost-active',
        ].join(' '),
      },
      size: {
        sm: 'gap-0.5 px-3 py-1',
        md: 'gap-0.5 px-4 py-2',
        lg: 'gap-1 px-5 py-2.5',
      },
    },
    defaultVariants: {
      variant: 'primary',
      size: 'md',
    },
  },
)

export interface ButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement>,
    VariantProps<typeof buttonVariants> {
  className?: string
  variant?: 'primary' | 'secondary' | 'outline' | 'ghost'
  size?: 'sm' | 'md' | 'lg'
  leftIcon?: IconNames
  rightIcon?: IconNames
  disabled?: boolean
  loading?: boolean
  asChild?: boolean
}

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      children,
      className,
      variant,
      size,
      leftIcon,
      rightIcon,
      asChild = false,
      disabled = false,
      loading = false,
      type = 'button',
      ...props
    }: ButtonProps,
    ref,
  ) => {
    const { t } = useLingui()
    const Comp = asChild ? Slot : 'button'

    return (
      <Comp
        className={cn(buttonVariants({ variant, size, className }), {
          'button-loading': loading,
        })}
        ref={ref}
        type={type}
        disabled={disabled || loading}
        {...props}
      >
        {leftIcon && (
          <CustomIcon name={leftIcon} size={size} fill="fill-current" />
        )}

        {loading && (
          <span className="animation-wrapper" aria-label={t`Loading`}>
            <PulseAnimation />
          </span>
        )}

        <Slottable>{loading ? <span>{children}</span> : children}</Slottable>

        {rightIcon && (
          <CustomIcon name={rightIcon} size={size} fill="fill-current" />
        )}
      </Comp>
    )
  },
)
Button.displayName = 'Button'

export { Button }
