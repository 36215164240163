import { HTMLAttributes } from 'react'
import { cn } from '@vgw/tailwind-merger'

const Header = ({ className, ...props }: HTMLAttributes<HTMLDivElement>) => (
  <div
    className={cn(
      [
        'flex',
        'flex-col',
        'gap-1',

        'px-2',
        'py-3',

        'sm:px-3',

        'md:px-4',
        'md:py-4',

        'lg:px-6',
        'lg:pt-6',

        'xl:px-8',
        'xl:pt-8',
        'xl:pb-6',
      ],
      className,
    )}
    {...props}
  />
)

Header.displayName = 'DialogHeader'

export { Header }
