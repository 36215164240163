import { forwardRef, useContext } from 'react'
import {
  Content,
  Portal,
  SelectContentProps,
  SelectScrollDownButton,
  SelectScrollUpButton,
  Viewport,
} from '@radix-ui/react-select'
import { cn } from '@vgw/tailwind-merger'
import { SelectContext } from './select.context'
import { CustomIcon } from '@vgw/multibrand-fe-icon'

const SelectContent = forwardRef<HTMLDivElement, SelectContentProps>(
  ({ style, className, children, ...props }, ref) => {
    const { error } = useContext(SelectContext)

    return (
      <Portal>
        <Content
          ref={ref}
          className={cn(
            ['z-10', 'overflow-hidden', 'rounded-md', 'border', 'border-solid'],
            error ? 'border-status-error' : 'border-form-input-border-active',
            className,
          )}
          position="popper"
          align="start"
          arrowPadding={200}
          collisionPadding={0}
          sideOffset={4}
          style={{
            width: 'var(--radix-select-trigger-width)',
            maxHeight: 'var(--radix-popper-available-height)',
            ...style,
          }}
          {...props}
        >
          <>
            <SelectScrollUpButton
              className={cn(
                [
                  'flex',
                  'items-center',
                  'justify-center',

                  'px-2.5',
                  'py-0.5',

                  'bg-form-input-surface',
                  'z-10',
                  'shadow-[0_2px_4px_rgba(0,0,0,0.15)]',
                ],
                error ? 'border-b-status-error' : '',
              )}
            >
              <CustomIcon name="chevron-down" className="rotate-180" />
            </SelectScrollUpButton>
            <Viewport ref={ref} className="w-full">
              {children}
            </Viewport>
            <SelectScrollDownButton
              className={cn(
                [
                  'flex',
                  'items-center',
                  'justify-center',

                  'px-2.5',
                  'py-0.5',

                  'border-t',
                  'bg-form-input-surface',
                  'z-10',
                  'shadow-[0_-2px_4px_rgba(0,0,0,0.15)]',
                ],
                error ? 'border-t-status-error' : '',
              )}
            >
              <CustomIcon name="chevron-down" />
            </SelectScrollDownButton>
          </>
        </Content>
      </Portal>
    )
  },
)

SelectContent.displayName = 'SelectContent'

export { SelectContent }
