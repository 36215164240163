import { Trans } from '@lingui/react/macro'
import { SegmentedStoreItem } from '../segmented-store-item/segmented-store-item'
import { goldCoinImageMap } from '../assets/gold-coins'

type OfferItem = {
  id: string
  price: number
  currency: string
  contents: { type: 'GC' | 'SC' | 'FSC' | 'RSC'; quantity: number }[]
}

type RegularOfferItemsSetProps = {
  items: OfferItem[]
}

export const RegularOfferItemsSet = ({ items }: RegularOfferItemsSetProps) => {
  const onItemBuy = () => {}

  return (
    <section className="w-full" data-testid="regular-offer-items-set-wrapper">
      <h3 className="flex gap-2 font-display text-lg font-bold leading-7">
        <Trans>Buy Gold Coins</Trans>
        <img
          alt=""
          src={goldCoinImageMap.multistack_gconly_md.small}
          width={30}
          height={30}
        />
      </h3>
      <div className="mt-3 flex flex-col gap-3">
        {items.map((item) => {
          return (
            <SegmentedStoreItem
              id={item.id}
              key={item.id}
              price={item.price}
              contents={item.contents}
              currency={item.currency}
              onBuyClick={onItemBuy}
            />
          )
        })}
      </div>
    </section>
  )
}
