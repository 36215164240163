import React from 'react'
import { CustomIcon, IconNames, IconSizes } from '@vgw/multibrand-fe-icon'
import { cva, VariantProps } from 'class-variance-authority'
import { Slot, Slottable } from '@radix-ui/react-slot'
import { cn } from '@vgw/tailwind-merger'

const iconButtonVariants = cva(
  [
    'group',
    'rounded-radius-icon-button',
    'outline-offset-2',
    'focus:outline-button-border-focus',
    'disabled:bg-disabled',
    'disabled:fill-content-disabled',
  ].join(' '),
  {
    variants: {
      variant: {
        primary: [
          'bg-button-surface-brand-primary',
          'fill-button-icon-brand-primary',
          'hover:bg-button-surface-brand-primary-hover',
          'active:bg-button-surface-brand-primary-active',
        ].join(' '),
        secondary: [
          'bg-button-surface-brand-secondary',
          'fill-button-icon-brand-secondary',
          'hover:bg-button-surface-brand-secondary-hover',
          'active:bg-button-surface-brand-secondary-active',
        ].join(' '),
        ghost: [
          'fill-button-icon-ghost',
          'hover:bg-button-surface-ghost-hover',
          'active:bg-button-surface-ghost-active',
        ].join(' '),
        outline: [
          'border',
          'border-button-icon-outline',
          'fill-button-icon-outline',
          'hover:bg-button-surface-ghost-hover',
          'active:bg-button-surface-ghost-active',
          'disabled:border-transparent',
        ].join(' '),
      },
      size: {
        sm: 'p-1.5',
        md: 'p-2',
        lg: 'p-2.5',
        xl: 'p-2',
        '2xl': 'p-2.5',
        '3xl': 'p-3',
      },
    },
    defaultVariants: {
      variant: 'primary',
      size: 'md',
    },
  },
)

export interface IconButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement>,
    VariantProps<typeof iconButtonVariants> {
  icon: IconNames
  hoverIcon?: IconNames
  variant?: 'primary' | 'secondary' | 'outline' | 'ghost'
  size?: 'sm' | 'md' | 'lg' | 'xl' | '2xl' | '3xl'
  iconSize?: IconSizes
  disabled?: boolean
  asChild?: boolean
}

const IconButton = React.forwardRef<HTMLButtonElement, IconButtonProps>(
  (
    {
      children,
      className,
      variant,
      size,
      iconSize,
      icon,
      hoverIcon,
      type = 'button',
      asChild = false,
      ...props
    }: IconButtonProps,
    ref,
  ) => {
    const Comp = asChild ? Slot : 'button'

    return (
      <Comp
        className={cn(iconButtonVariants({ variant, size, className }))}
        ref={ref}
        type={type}
        {...props}
      >
        <CustomIcon
          name={icon}
          hoverName={hoverIcon}
          size={iconSize || size}
          fill="disabled:fill-content-disabled"
        />

        <Slottable>{children}</Slottable>
      </Comp>
    )
  },
)
IconButton.displayName = 'IconButton'

export { IconButton }
