import { Trans } from '@lingui/react/macro'
import { SubMenuNavigationItem } from './sub-menu-navigation-item'
import { msg } from '@lingui/core/macro'
import { Button } from '@vgw/multibrand-fe-button'
import { cn } from '@vgw/tailwind-merger'
import { useScrollDirection } from './use-scroll-direction'

const links = [
  {
    label: msg`Home`,
    path: '',
  },
  {
    label: msg`Slots`,
    path: '/slots',
  },
  {
    label: msg`Bingo`,
    path: '/bingo',
  },
  {
    label: msg`Jackpot`,
    path: '/jackpot',
  },
  {
    label: msg`Slingo`,
    path: '/slingo',
  },
  {
    label: msg`Scratchcards`,
    path: '/scratchcards',
  },
  {
    label: msg`Instant Win`,
    path: '/instant-win',
  },
  {
    label: msg`Table Games`,
    path: '/table-games',
  },
]

export const SubMenuNavigation = () => {
  const scrollDirection = useScrollDirection()

  return (
    <div
      className={cn(
        'sticky z-20 hidden w-full justify-center bg-surface-base shadow-header transition-all lg:flex',
        {
          '-top-[56px] z-10': scrollDirection === 'down',
          'top-[56px]': scrollDirection === 'up',
        },
      )}
      data-testid="header-sub-menu-navigation"
    >
      <ul className="flex w-full max-w-screen-2xl gap-0.5 px-6 py-2">
        <Button
          size="sm"
          variant="ghost"
          className="text-nowrap rounded-full text-xs font-medium leading-4"
          leftIcon="search"
        >
          <Trans>Search</Trans>
        </Button>
        {links.map(({ label, path }) => (
          <SubMenuNavigationItem key={path} label={label} path={path} />
        ))}
      </ul>
    </div>
  )
}
