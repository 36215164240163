import { useMutation } from '@tanstack/react-query'
import { useFetch } from '@/use-fetch'
import { CoinType } from '@/selected-coin-type-context'

interface GetGameUrlResponse {
  launchUrl: string
}

interface GetGameUrlArgs {
  launchUrl: string
  selectedCoinType: CoinType
}

function useGameUrlMutation() {
  const { post } = useFetch()
  const { data, error, isPending, mutate } = useMutation({
    mutationFn: ({ launchUrl, selectedCoinType }: GetGameUrlArgs) => {
      const url = new URL(launchUrl)
      url.searchParams.set('coinType', selectedCoinType)
      url.searchParams.set('isMobile', 'false')
      url.searchParams.set('operator', 'FAK')
      url.searchParams.set('userId', '1')
      return post(url.toString()) as Promise<GetGameUrlResponse>
    },
  })

  return {
    getGameUrl: mutate,
    loading: isPending,
    error,
    data,
  }
}

export { useGameUrlMutation }
