export const RedIndicator = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="7"
    height="6"
    viewBox="0 0 7 6"
    fill="none"
    data-testid="red-indicator"
  >
    <circle cx="3.25" cy="3" r="3" fill="#BE0935" />
  </svg>
)
