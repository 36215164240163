import { CustomIcon, IconNames } from '@vgw/multibrand-fe-icon'
import * as Tabs from '@radix-ui/react-tabs'
import { cn } from '@vgw/tailwind-merger'
import { RedIndicator } from './red-indicator'

interface NavItemProps extends Tabs.TabsTriggerProps {
  value: string
  label: string
  icon: IconNames
  navbarVariant: string
  hasNotification?: boolean
}

export const NavItem = ({
  value,
  label,
  icon,
  navbarVariant,
  className,
  hasNotification,
}: NavItemProps) => {
  // including 'group' as a className to link with the svg element in 'IconLabel > custom-icon > svg'
  return (
    <Tabs.Trigger
      data-testid={`nav-${value}-item`}
      className={cn(
        'data-[state=active]:text-content-base group flex h-auto w-full flex-col items-center justify-center gap-0.5',
        className,
      )}
      value={value}
    >
      <div className="flex flex-col items-center gap-1">
        <div
          className={cn(
            'relative',
            navbarVariant === 'secondary'
              ? 'group-data-[state=active]:bg-navigation-content-base flex w-full justify-center rounded-full py-1'
              : 'py-1',
          )}
        >
          {hasNotification && (
            <span className="absolute left-5">
              <RedIndicator />
            </span>
          )}
          <CustomIcon
            name={icon}
            size="md"
            title={label}
            fill="fill-navigation-content-base"
            className="group-data-[state=active]:fill-content-base"
          />
        </div>
        {label}
      </div>
    </Tabs.Trigger>
  )
}
