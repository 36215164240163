import { createContext } from 'react'
import { FormSectionSize } from './types'

interface ContextValue {
  size: FormSectionSize
}

export const FormSectionContext = createContext<ContextValue>({
  size: 'sm',
})
