import { NavLink } from 'react-router-dom'
import { useLingui } from '@lingui/react/macro'
import { MessageDescriptor } from '@lingui/core'
import { Button } from '@vgw/multibrand-fe-button'
import { useMatch } from 'react-router-dom'

interface SubMenuNavigationItemProps {
  label: MessageDescriptor
  path: string
}

export const SubMenuNavigationItem = ({
  label,
  path,
}: SubMenuNavigationItemProps) => {
  const { t } = useLingui()
  const isActive = useMatch(path)

  return (
    <li className="text-nowrap">
      <Button
        asChild
        size="sm"
        variant={isActive ? 'secondary' : 'ghost'}
        className="rounded-full text-xs font-medium leading-4"
      >
        <NavLink to={path}>{t(label)}</NavLink>
      </Button>
    </li>
  )
}
