import { Duration, add } from 'date-fns'
import { useLingui } from '@lingui/react/macro'

export const useDurationFormat = () => {
  const { t } = useLingui()

  return (duration: Duration) => {
    const { days = 0, hours = 0 } = duration || {}

    const minutes = (duration.minutes || 0).toString().padStart(2, '0')
    const seconds = (duration.seconds || 0).toString().padStart(2, '0')

    if (days >= 7) {
      return new Intl.DateTimeFormat('en-US', {
        dateStyle: 'short',
      }).format(add(new Date(), duration))
    }

    if (days >= 1) {
      if (hours > 0) {
        return t`${days}d ${hours}h`
      }

      return t`${days}d ${minutes}m`
    }

    if (hours > 1) {
      return t`${hours}h ${minutes}m`
    }

    return t`${minutes}m ${seconds}s`
  }
}
