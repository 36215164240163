import { Trans } from '@lingui/react/macro'
import { Button } from '@vgw/multibrand-fe-button'
import { useMemo } from 'react'
import { Chip } from '@vgw/multibrand-fe-chip'
import { cn } from '@vgw/tailwind-merger'
import {
  GcFormatter,
  priceFormatter,
  ScFormatter,
} from '@/features/store/store-price-formatter'
import { goldCoinImageMap } from '../assets/gold-coins'

export interface SegmentedStoreItemProps {
  id: string
  image?: keyof typeof goldCoinImageMap
  price: number
  currency: string
  contents: { type: 'GC' | 'SC' | 'FSC' | 'RSC'; quantity: number }[]
  onBuyClick: (id: string) => void
}

export const SegmentedStoreItem = ({
  id,
  image = 'multistack_gconly_md',
  price,
  currency,
  contents,
  onBuyClick,
}: SegmentedStoreItemProps) => {
  const formatter = useMemo(() => priceFormatter(currency), [currency])

  const gc = useMemo(
    () => contents.find(({ type }) => type === 'GC')?.quantity,
    [contents],
  )
  const sc = useMemo(
    () => contents.find(({ type }) => type === 'SC')?.quantity,
    [contents],
  )

  const isGoldCoinDefined = typeof gc === 'number'
  const isSweepCoinDefined = typeof sc === 'number'

  const smallImage =
    goldCoinImageMap[image]?.small ||
    goldCoinImageMap.multistack_gconly_md.small
  const mediumImage =
    goldCoinImageMap[image]?.medium ||
    goldCoinImageMap.multistack_gconly_md.medium

  return (
    <div className="relative w-full pl-6" data-testid="segmented-store-item">
      <img
        className="absolute left-0"
        src={smallImage}
        srcSet={`${smallImage} 60w, ${mediumImage} 120w`}
        width={60}
        height={60}
        alt=""
      />
      <div className="flex items-center justify-between gap-2 rounded-radius-button-brand-primary bg-surface-muted p-2 pl-11">
        <div
          className={cn(
            'flex flex-1 flex-wrap justify-between gap-x-3 gap-y-0.5',
          )}
        >
          {isGoldCoinDefined && (
            <p data-testid="segmented-store-item-gc">
              <span className="mr-0.5 font-bold">
                <Trans>GC</Trans>
              </span>
              {GcFormatter.format(gc || 0)}
            </p>
          )}
          {isSweepCoinDefined && (
            <div
              className="flex items-center"
              data-testid="segmented-store-item-sc"
            >
              <span className="font-bold">+</span>
              <Chip className="mx-1" variant="tag">
                <Trans>FREE</Trans>
              </Chip>
              <span className="mr-0.5 font-medium">
                <Trans>SC</Trans>
              </span>
              {ScFormatter.format(sc / 100)}
            </div>
          )}
        </div>
        <Button onClick={() => onBuyClick(id)} className="w-[83px]">
          {formatter.format(price / 100)}
        </Button>
      </div>
    </div>
  )
}
