import('./pulse-animation.styles.css')

function PulseAnimation() {
  return (
    <span className="pulse-animation">
      <span className="dot"></span>
      <span className="dot"></span>
      <span className="dot"></span>
    </span>
  )
}

export { PulseAnimation }
