import { ComponentProps, ElementRef, forwardRef } from 'react'
import { DialogTitle, Title as PrimitiveTitle } from '@radix-ui/react-dialog'
import { cn } from '@vgw/tailwind-merger'

const Title = forwardRef<
  ElementRef<typeof DialogTitle>,
  ComponentProps<typeof DialogTitle>
>(({ className, children, ...props }, ref) => (
  <PrimitiveTitle
    className={cn(
      [
        'text-content-base',

        'font-bold',
        'text-lg',
        'leading-7',

        'lg:text-xl',
        'lg:leading-8',

        'xl:text-2xl',
        'xl:leading-9',
      ],
      className,
    )}
    ref={ref}
    {...props}
  >
    {children}
  </PrimitiveTitle>
))

Title.displayName = 'DialogTitle'

export { Title }
