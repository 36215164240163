import { IconButton } from '@vgw/multibrand-fe-icon-button'
import { Link } from 'react-router-dom'
import { GAME_PLAY_PATH } from '@/config/paths'
import { GameCardData } from '@/features/game-api/use-game-info-query'
import { useLingui } from '@lingui/react/macro'

interface GameTitleProps {
  gameCard: GameCardData
  isFavorite: boolean
  handleToggleFavorite: () => void
}

export const GameTitle = ({
  gameCard,
  isFavorite,
  handleToggleFavorite,
}: GameTitleProps) => {
  const { t } = useLingui()

  return (
    <div className="flex h-11 items-center justify-between self-stretch">
      <h1 className="text-2xl font-bold leading-9 tracking-normal">
        {gameCard.name}
      </h1>
      <div className="flex items-center gap-3">
        <IconButton
          aria-label={
            isFavorite ? t`Remove from favorites` : t`Add to favorites`
          }
          icon={isFavorite ? 'coloured:favourite-filled' : 'favourite'}
          hoverIcon={
            isFavorite
              ? 'coloured:favourite-filled-hover'
              : 'coloured:favourite'
          }
          size="lg"
          variant="outline"
          onClick={handleToggleFavorite}
        />

        <IconButton
          asChild
          aria-label={t`Play now`}
          size="3xl"
          icon="play-filled"
          iconSize="4xl"
        >
          <Link
            to={`${GAME_PLAY_PATH}/${gameCard.gameId}`}
            state={{ launchUrl: gameCard.launchUrl }}
          />
        </IconButton>
      </div>
    </div>
  )
}
