import { JSX, useCallback, useEffect, useState } from 'react'
import useEmblaCarousel from 'embla-carousel-react'
import { AutoPlay } from '@vgw/multibrand-fe-carousel/src/plugins/autoplay-plugin'
import { Carousel } from '@vgw/multibrand-fe-carousel'
import { cn } from '@vgw/tailwind-merger'

interface OverlayedHeroBannerBaseProps {
  className?: string
  cards: OverlayedHeroBannerBaseCard[]
  onCardEntersViewport?: (cardIndex: number) => void
  /**
   * Set the autoplay speed. Set to false if you want to disable autoplay completely.
   */
  autoPlayDelay?: number | false
}

export interface OverlayedHeroBannerBaseCard {
  MainElement: (props: { className: string }) => JSX.Element
  ThumbnailElement: (props: { className: string }) => JSX.Element
}

export const OverlayedHeroBannerBase = ({
  className,
  cards,
  onCardEntersViewport,
  autoPlayDelay = 7000,
}: OverlayedHeroBannerBaseProps) => {
  const [selectedIndex, setSelectedIndex] = useState(0)
  const [emblaMainRef, emblaMainApi] = useEmblaCarousel({}, [
    ...(autoPlayDelay !== false
      ? [
          AutoPlay({
            rootNode: (emblaRoot: HTMLElement) =>
              emblaRoot.closest('.js-carousel-container'),
            playOnInit: true,
            delay: autoPlayDelay,
            stopOnMouseEnter: true,
            stopOnInteraction: false,
          }),
        ]
      : []),
  ])

  const onThumbClick = (index: number) => {
    if (!emblaMainApi) return
    emblaMainApi.scrollTo(index)
  }

  const onSelect = useCallback(() => {
    if (!emblaMainApi) return
    setSelectedIndex(emblaMainApi.selectedScrollSnap())
    onCardEntersViewport?.(emblaMainApi.selectedScrollSnap())
  }, [emblaMainApi, setSelectedIndex, onCardEntersViewport])

  useEffect(() => {
    if (!emblaMainApi) return
    onSelect()

    emblaMainApi.on('select', onSelect).on('reInit', onSelect)

    return () => {
      emblaMainApi.off('select', onSelect).off('reInit', onSelect)
    }
  }, [emblaMainApi, onSelect])

  return (
    <Carousel
      className={cn(
        className,
        'js-carousel-container relative flex w-full items-center pb-[67px] sm:pb-[74px] md:pb-[102px] lg:pb-[132px] xl:pb-0 xl:pr-[92px] 2xl:pr-[140px]',
      )}
    >
      <Carousel.Container ref={emblaMainRef} className="w-full">
        <Carousel.CardContainer>
          {cards.map(({ MainElement }, index) => (
            <Carousel.Card
              key={index}
              index={index}
              className="flex w-full items-center"
              data-testid={`card-${index}`}
            >
              <MainElement className="aspect-video h-full w-full object-cover" />
            </Carousel.Card>
          ))}
        </Carousel.CardContainer>
      </Carousel.Container>
      <div className="absolute bottom-[22px] left-0 right-0 flex items-center justify-center gap-3 md:gap-4 xl:bottom-auto xl:left-auto xl:right-[22px] xl:flex-col">
        {cards.map(({ ThumbnailElement }, index) => (
          <button
            key={index}
            className="h-[67px] w-[67px] sm:h-[74px] sm:w-[74px] md:h-[102px] md:w-[102px] lg:h-[132px] lg:w-[132px] xl:h-[92px] xl:w-[92px] 2xl:h-[140px] 2xl:w-[140px]"
            data-testid="overlayed-hero-banner-thumbnail-button"
            onClick={() => onThumbClick(index)}
          >
            <ThumbnailElement
              className={cn('rounded-radius-base h-full w-full object-cover', {
                'outline outline-2 outline-white': selectedIndex === index,
              })}
            />
          </button>
        ))}
      </div>
    </Carousel>
  )
}
