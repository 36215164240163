import { Chip } from '@vgw/multibrand-fe-chip'
import { CustomIcon } from '@vgw/multibrand-fe-icon'
import { useDurationFormat } from '@/features/datetime-utils/use-duration-format'
import { useCountdownDuration } from '@/features/datetime-utils/use-countdown-duration'

export type TimeLimitProps = {
  validUntil: Date
}

export const TimeLimit = ({ validUntil }: TimeLimitProps) => {
  const formatDuration = useDurationFormat()
  const duration = useCountdownDuration(validUntil)

  if (!duration) {
    return null
  }

  return (
    <Chip className="flex items-center gap-0.5">
      <CustomIcon
        name={duration.days! > 0 ? 'calendar' : 'clock'}
        size="xs"
        fill="fill-current"
      />

      <span>{formatDuration(duration)}</span>
    </Chip>
  )
}
