import { IconNames } from '@vgw/multibrand-fe-icon'

export const FOOTER_LOGOS = [
  {
    name: 'coloured:mga' satisfies IconNames,
    url: 'https://authorisation.mga.org.mt/verification.aspx?lang=EN&company=4debab56-21d5-49fd-96c5-1c2b5500dbb4&details=1',
    size: '3xl',
    className: 'w-auto',
  },
  {
    name: 'coloured:rgf' satisfies IconNames,
    url: 'https://www.rgf.org.mt',
    size: '3xl',
  },
  {
    name: 'coloured:18' satisfies IconNames,
    url: 'https://cgaa.info',
    size: '3xl',
  },
] as const

export const SOCIAL_MEDIA_LOGOS = [
  {
    name: 'facebook' satisfies IconNames,
    url: 'https://www.facebook.com',
  },
  {
    name: 'instagram' satisfies IconNames,
    url: 'https://www.instagram.com',
  },
  {
    name: 'x' satisfies IconNames,
    url: 'https://twitter.com',
  },
] as const
