import { forwardRef, ReactNode } from 'react'
import {
  AccordionMultipleProps,
  AccordionSingleProps,
  Item,
  Root,
} from '@radix-ui/react-accordion'
import { AccordionTrigger } from './components/accordion-trigger'
import { AccordionContent } from './components/accordion-content'

export interface AccordionItemProps {
  content: ReactNode
  label: string
  value: string
  isDisabled?: boolean
}

export interface AccordionProps {
  items: AccordionItemProps[]
}

const Accordion = forwardRef<
  HTMLDivElement,
  AccordionProps & (AccordionSingleProps | AccordionMultipleProps)
>(({ className, items, ...props }, ref) => (
  <Root className={className} {...props} ref={ref}>
    {items.map(({ content, label, value, isDisabled }) => (
      <Item key={value} value={value} disabled={isDisabled}>
        <AccordionTrigger>{label}</AccordionTrigger>
        <AccordionContent>{content}</AccordionContent>
      </Item>
    ))}
  </Root>
))

Accordion.displayName = Root.displayName

export { Accordion }
