import { HTMLAttributes, useMemo } from 'react'
import { useLingui } from '@lingui/react/macro'
import { cn } from '@vgw/tailwind-merger'
import { Button } from '@vgw/multibrand-fe-button'
import { Chip } from '@vgw/multibrand-fe-chip'
import {
  GcFormatter,
  priceFormatter,
  ScFormatter,
} from '@/features/store/store-price-formatter'
import chest52wImageUrl from './assets/chest-52w.png'
import chest104wImageUrl from './assets/chest-104w.png'

interface SpecialOfferItemProps extends HTMLAttributes<HTMLDivElement> {
  id: string
  originalPrice: number
  discountPrice: number
  currency: string
  gc: number
  sc?: number
  onBuyClick: (id: string) => void
}

const SpecialOfferItem = ({
  className,
  id,
  originalPrice,
  discountPrice,
  currency,
  gc,
  sc,
  onBuyClick,
  ...props
}: SpecialOfferItemProps) => {
  const { t } = useLingui()
  const formatter = useMemo(() => priceFormatter(currency), [currency])

  const isSweepCoinDefined = typeof sc === 'number'

  return (
    <div
      data-testid="special-offer-item"
      className={cn(
        [
          'w-full',
          'p-2',

          'flex',
          'justify-between',
          'items-center',
          'gap-1',

          'rounded-md',

          'bg-gradient-to-r',
          'from-[#DFF1F6]',
          'to-[#00A3FF]',
        ],
        className,
      )}
      {...props}
    >
      <div className="flex items-center gap-1.5 pr-2">
        <img
          width={52}
          height={52}
          src={chest52wImageUrl}
          srcSet={`${chest52wImageUrl} 52w, ${chest104wImageUrl} 104w`}
          alt=""
        />
        <div
          className={cn([
            'text-base',
            'text-content-base',
            'flex',
            'flex-wrap',
            'items-center',
            'md:justify-center',
            'gap-0.5',
            'md:gap-1',
            'flex-1',
          ])}
        >
          <span
            className={cn(['flex', 'items-center', 'gap-0.5', 'md:gap-1'])}
            data-testid="special-offer-item-gc"
          >
            <strong>{t`GC`}</strong> {GcFormatter.format(gc)}
          </span>
          {isSweepCoinDefined && (
            <span
              className={cn(['flex', 'items-center', 'gap-0.5', 'md:gap-1'])}
              data-testid="special-offer-item-sc"
            >
              <strong>+</strong>
              <Chip variant="tag" className="h-5">
                {t`FREE`}
              </Chip>
              <strong>{t`SC`}</strong> {ScFormatter.format(sc / 100)}
            </span>
          )}
        </div>
      </div>
      <div>
        <Button
          className={cn(['flex', 'flex-col', 'gap-0', 'p-1.5'])}
          onClick={() => onBuyClick(id)}
        >
          <span
            className={cn([
              'text-xs',
              'leading-3',
              'text-white-alpha-700',
              'line-through',
            ])}
          >
            {t`Was`} {formatter.format(originalPrice / 100)}
          </span>
          <span
            className={cn([
              'text-base',
              'leading-5',
              'drop-shadow-[0.5px_0.5px_0_rgba(0,0,0,0.25)]',
            ])}
          >
            <strong>{formatter.format(discountPrice / 100)}</strong>
          </span>
        </Button>
      </div>
    </div>
  )
}

SpecialOfferItem.displayName = 'SpecialOfferItem'

export { SpecialOfferItem }
