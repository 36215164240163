import { useMemo } from 'react'
import { useLingui } from '@lingui/react/macro'
import { cn } from '@vgw/tailwind-merger'
import { type ClassValue } from 'clsx'
import {
  CarouselVariant,
  CarouselHeroBannerBase,
  CarouselHeroBannerBaseCard,
} from './carousel-hero-banner-base'

interface CarouselHeroBannerCard {
  src: string
  title: string
  url?: string
  sizes?: string
  srcSet?: string
  onCardClick?: () => void
}

interface CarouselHeroBannerProps {
  cards: CarouselHeroBannerCard[]
  /**
   * Set the autoplay speed. Set to false if you want to disable autoplay completely.
   */
  autoPlayDelay?: number | false
  variant?: CarouselVariant
}

export const CarouselHeroBanner = ({
  cards,
  autoPlayDelay = 7000,
  variant,
}: CarouselHeroBannerProps) => {
  const { t } = useLingui()

  const slides = useMemo(
    () =>
      cards.map<CarouselHeroBannerBaseCard>((card) => {
        return {
          MainElement: ({
            classNames: [mainClassName, ...variantClassNames],
          }) => {
            const img = ({
              card,
              extraClassNames,
            }: {
              card: CarouselHeroBannerCard
              extraClassNames?: ClassValue[]
            }) => (
              <img
                alt={card.url ? t`Navigate to ${card.title}` : card.title}
                src={card.src}
                sizes={card.sizes}
                srcSet={card.srcSet}
                className={cn(mainClassName, extraClassNames)}
              />
            )

            return card.url ? (
              <a
                href={card.url}
                target="_blank"
                rel="noreferrer"
                onClick={() => card.onCardClick?.()}
                className={cn(variantClassNames)}
              >
                {img({ card, extraClassNames: ['h-full'] })}
              </a>
            ) : (
              img({
                card,
                extraClassNames: variantClassNames,
              })
            )
          },
        }
      }),
    [cards, t],
  )

  return (
    <CarouselHeroBannerBase
      cards={slides}
      autoPlayDelay={autoPlayDelay}
      variant={variant}
    />
  )
}
