import { Link } from 'react-router-dom'
import { Trans } from '@lingui/react/macro'

interface FooterDisclaimerProps {
  userHasSweepsCoinsFeature: boolean
}

export const Disclaimer = ({
  userHasSweepsCoinsFeature,
}: FooterDisclaimerProps) => {
  return (
    <p className="text-xs font-bold lg:block lg:text-center">
      <Trans>
        No purchase necessary. VGW Group. Void where prohibited by law. See{' '}
        <Link className="underline" to="sweeps-law">
          T&Cs
        </Link>
        . 18+. For detailed rules, see{' '}
        <Link className="underline" to="sweeps-rules">
          Sweeps Rules
        </Link>
        .
      </Trans>

      {userHasSweepsCoinsFeature && (
        <>
          {' '}
          <Trans>
            Buy a special{' '}
            <Link className="underline" to="gc-package">
              Gold Coin only package
            </Link>
          </Trans>
        </>
      )}
    </p>
  )
}
