import { SelectedCoinTypeContextProvider } from '@/selected-coin-type-context'
import { I18nProvider } from '@lingui/react'
import { i18n } from '@lingui/core'
import { OidcProvider } from '@/features/oidc/oidc'
import { Tracking } from '@/tracking'
import { ReactQueryProvider } from '@/providers/react-query-povider'
import { createBrowserRouter, RouterProvider } from 'react-router-dom'
import Root from '@/layout/root'
import Home from '@/pages/home/home'
import { GAME_CATEGORY_PATH, GAME_PATH, GEO_BLOCK_PATH } from '@/config/paths'
import GamePlay from '@/pages/game-play/game-play'
import { CategoryPage } from '@/pages/category'
import ErrorPage from '@/pages/error/error'
import GeoBlock from '@/pages/geo-block/geo-block'
import { Sdd } from '@/pages/account/sdd/sdd'
import { protectedRouteLoader } from '@/features/oidc/protected-route-loader'

const router = createBrowserRouter(
  [
    {
      path: '/',
      element: <Root />,
      errorElement: <ErrorPage />,
      children: [
        {
          path: '',
          element: <Home />,
        },
      ],
    },
    {
      path: '/',
      element: <Root isInGame={true} renderSubNavigation={false} />,
      errorElement: <ErrorPage />,
      children: [
        {
          path: GAME_PATH,
          element: <GamePlay />,
          loader: protectedRouteLoader,
        },
      ],
    },
    {
      path: '/',
      element: <Root />,
      errorElement: <ErrorPage />,
      children: [
        {
          path: GAME_CATEGORY_PATH,
          element: <CategoryPage />,
        },
      ],
    },
    {
      path: '/',
      element: <Root renderHeader={false} renderBackground />,
      children: [
        {
          path: GEO_BLOCK_PATH,
          element: <GeoBlock />,
        },
      ],
    },
    {
      path: '/',
      element: (
        <Root
          renderHeader={false}
          renderFooter={false}
          mainClassName="bg-surface-tertiary"
        />
      ),
      children: [
        {
          path: '/account/sdd',
          element: <Sdd />,
        },
      ],
    },
  ],
  { basename: import.meta.env.BASE_URL },
)

export const App = ({ amplitudeKey }: { amplitudeKey: string }) => {
  return (
    <SelectedCoinTypeContextProvider>
      <I18nProvider i18n={i18n}>
        <OidcProvider>
          <Tracking apiKey={amplitudeKey}>
            <ReactQueryProvider>
              <RouterProvider router={router} />
            </ReactQueryProvider>
          </Tracking>
        </OidcProvider>
      </I18nProvider>
    </SelectedCoinTypeContextProvider>
  )
}
