import { HTMLAttributes } from 'react'
import { cn } from '@vgw/tailwind-merger'

type Props = HTMLAttributes<HTMLSelectElement>

const Carousel = ({ className, ...props }: Props) => (
  <div className="max-w-full flex-grow">
    <section className={cn('max-w-full', className)} {...props} />
  </div>
)

export { Carousel }
