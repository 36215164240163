import { useMemo } from 'react'
import { cn } from '@vgw/tailwind-merger'
import {
  CarouselVariant,
  CarouselHeroBannerBase,
  CarouselHeroBannerBaseCard,
} from './carousel-hero-banner-base'

interface CarouselHeroBannerSkeletonProps {
  variant?: CarouselVariant
}

export const CarouselHeroBannerSkeleton = ({
  variant,
}: CarouselHeroBannerSkeletonProps) => {
  const slides = useMemo(
    () =>
      Array.from({ length: 4 }).map<CarouselHeroBannerBaseCard>(() => ({
        MainElement: ({ classNames }) => (
          <div
            className={cn(classNames, 'bg-skeleton w-full !animate-pulse')}
          />
        ),
      })),
    [],
  )

  return (
    <CarouselHeroBannerBase
      nonInteractive
      cards={slides}
      autoPlayDelay={false}
      variant={variant}
    />
  )
}
