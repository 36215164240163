import {
  DayContentProps,
  DayPicker,
  DayPickerBase,
  DayPickerSingleProps,
} from 'react-day-picker'
import { Caption } from './components/caption'
import { useCallback, useState } from 'react'
import { isSameDay } from 'date-fns'
import { cn } from '@vgw/tailwind-merger'
import { CalendarContext } from './calendar.context'

type Props = DayPickerBase &
  DayPickerSingleProps & {
    selected?: Date
    size?: 'sm' | 'md'
    official?: Date | Date[]
    inactiveYears?: number[]
    openTo?: 'year' | 'month'
    initialYear?: number
    yearsToRender?: number
  }

const Calendar = ({
  size = 'sm',
  inactiveYears,
  official = [],
  openTo,
  selected,
  initialYear = new Date().getFullYear() - 30,
  yearsToRender = 50,
  ...props
}: Props) => {
  const isDayOfficial = useCallback(
    (day: Date) => {
      if (Array.isArray(official)) {
        return official.some((date) => isSameDay(date, day))
      }

      return isSameDay(official, day)
    },
    [official],
  )
  const [isDefaultCaptionOpen, setIsDefaultCaptionOpen] = useState(
    openTo === 'year',
  )

  return (
    <CalendarContext.Provider
      value={{
        size,
        isDefaultCaptionOpen,
        setIsDefaultCaptionOpen,
        inactiveYears: new Set(inactiveYears),
        initialYear,
        yearsToRender,
      }}
    >
      <DayPicker
        showOutsideDays
        initialFocus
        selected={selected}
        defaultMonth={selected}
        className="border-0"
        components={{
          Caption,
          DayContent: (props: DayContentProps) => {
            return (
              <span
                className={cn(
                  isDayOfficial(props.date) && 'text-gold-coins-surface-active',
                )}
              >
                {props.date.getDate()}
              </span>
            )
          },
        }}
        classNames={{
          head_row: 'flex font-medium text-content-muted gap-2',
          head_cell:
            'flex align-middle justify-center font-normal text-sm text-content-muted p-1 w-7',
          row: 'flex w-full gap-2',
          table: 'flex flex-col gap-2',
          tbody: 'flex flex-col gap-2',
          cell: 'w-7 h-7 flex align-middle justify-center p-0',
          day: 'text-content-base text-center font-light font-bold w-full hover:bg-brand-secondary-shade hover:text-content-inverse rounded-md hover:font-medium text-base',
          day_selected:
            'bg-brand-secondary focus:bg-brand-secondary flex items-center justify-center !font-bold hover:bg-brand-secondary-shade',
          day_outside: 'text-content-disabled',
          day_disabled: 'text-content-disabled pointer-events-none',
          day_hidden: 'invisible',
          month:
            'flex flex-col p-3 gap-3 bg-form-input-surface rounded-md border border-solid border-form-input-border-active min-h-[326px]',
        }}
        {...props}
      />
    </CalendarContext.Provider>
  )
}

Calendar.displayName = 'Calendar'

export { Calendar }
