import { forwardRef, HTMLAttributes } from 'react'
import { cn } from '@vgw/tailwind-merger'

type ContainerProps = HTMLAttributes<HTMLDivElement>

const Container = forwardRef<HTMLDivElement, ContainerProps>(
  ({ className, ...rest }, ref) => (
    <div className={cn('overflow-hidden', className)} {...rest} ref={ref} />
  ),
)

Container.displayName = 'Carousel.Container'

export { Container }
