import { Button } from '@vgw/multibrand-fe-button'
import { accept, block } from '@vgw/mar-cookie-consent'
import { Trans } from '@lingui/react/macro'

export interface CookieConsentBannerProps {
  onCookiesBannerHide: () => void
}

export const CookieConsentBanner = ({
  onCookiesBannerHide,
}: CookieConsentBannerProps) => {
  const onAcceptAll = () => {
    onCookiesBannerHide()
    accept()
  }
  const onBlockAll = () => {
    onCookiesBannerHide()
    block()
  }

  return (
    <div className="fixed bottom-6 left-3 right-3 max-w-[22.875rem] space-y-4 rounded-md bg-surface-secondary px-3 py-6 shadow-drop sm:left-6">
      <p className="font-display text-lg font-bold xl:text-xl">
        <Trans>Our website use cookies</Trans>
      </p>
      <div className="pl-2 text-sm xl:text-base">
        <p className="inline">
          <Trans>
            By clicking “Accept All”, you agree to the storing of cookies on
            your device to enhance site navigation, analyze site usage, and
            assist in our marketing efforts. More details in our{' '}
            <a href="/#" className="underline">
              Privacy Policy
            </a>
            .
          </Trans>
        </p>
      </div>
      <div className="flex items-center gap-2 pl-2">
        <Button className="flex-1" onClick={onAcceptAll}>
          <Trans>Accept All</Trans>
        </Button>
        <Button className="flex-1" onClick={onBlockAll} variant="secondary">
          <Trans>Block All</Trans>
        </Button>
      </div>
    </div>
  )
}
