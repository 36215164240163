import { HTMLAttributes, ReactNode } from 'react'
import { cn } from '@vgw/tailwind-merger'

export interface BodyProps extends HTMLAttributes<HTMLDivElement> {
  children: ReactNode
  isContentPadded?: boolean
}

const Body = ({
  className,
  children,
  isContentPadded = true,
  ...props
}: BodyProps) => (
  <div
    className={cn(
      'overflow-auto',
      {
        'px-2 sm:px-3 md:px-4 lg:px-6 xl:px-8': isContentPadded,
      },
      className,
    )}
    {...props}
  >
    {children}
  </div>
)

Body.displayName = 'DialogBody'

export { Body }
