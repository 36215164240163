import * as Dialog from '@radix-ui/react-dialog'
import React from 'react'
import { cn } from '@vgw/tailwind-merger'
import { IconButton } from '@vgw/multibrand-fe-icon-button'
import { cva, type VariantProps } from 'class-variance-authority'
import { useLingui } from '@lingui/react/macro'

const Drawer = Dialog.Root

const DrawerTrigger = Dialog.Trigger

interface DrawerContentProps
  extends React.ComponentPropsWithoutRef<typeof Dialog.Content>,
    VariantProps<typeof variants> {}

const variants = cva(
  [
    'bg-surface-neutral',
    'text-content-base',
    'fixed',
    'z-50',
    'h-full',
    'w-full',
    'focus:outline-none',
    'shadow-[0_0_16px_rgba(0,0,0,0.6)]',
    'flex',
    'overflow-y-auto',
  ],
  {
    variants: {
      position: {
        right:
          'data-[state=open]:slide-in-from-right data-[state=closed]:slide-out-to-right right-0 top-0 max-w-sm',
        left: 'data-[state=open]:slide-in-from-left data-[state=closed]:slide-out-to-left left-0 top-0 max-w-sm',
        top: 'data-[state=open]:slide-in-from-top data-[state=closed]:slide-out-to-top left-0 top-0 max-h-96 w-full',
        bottom:
          'data-[state=open]:slide-in-from-bottom data-[state=closed]:slide-out-to-bottom bottom-0 left-0 max-h-96 w-full',
      },
      insetContent: {
        true: 'p-6 pt-8',
        false: '',
      },
    },
    defaultVariants: {
      position: 'right',
      insetContent: true,
    },
  },
)

const DrawerContent = React.forwardRef<
  React.ElementRef<typeof Dialog.Content>,
  DrawerContentProps
>(({ className, children, position, insetContent }, ref) => {
  const { t } = useLingui()

  return (
    <Dialog.Portal>
      <Dialog.Overlay
        className={cn(
          'bg-overlay-dark fixed inset-0 z-40',
          'data-[state=open]:animate-in data-[state=open]:fade-in-0 transition-opacity',
          'data-[state=closed]:animate-out data-[state=closed]:fade-out-0',
        )}
      />
      <Dialog.Content
        ref={ref}
        className={cn(
          'data-[state=open]:animate-in data-[state=open]:fade-in-0 transition',
          'data-[state=closed]:animate-out',
          variants({ position, insetContent }),
          className,
        )}
      >
        <div className="flex h-full w-full flex-col items-start gap-3 rounded-lg">
          {children}
        </div>
        <Dialog.Close asChild>
          <IconButton
            data-testid="drawer-close"
            className="pointer-events-auto absolute right-3 top-3"
            size="sm"
            icon="cross-1"
            variant="ghost"
            aria-label={t`Close drawer button`}
          />
        </Dialog.Close>
      </Dialog.Content>
    </Dialog.Portal>
  )
})

Drawer.displayName = 'Drawer'
DrawerTrigger.displayName = 'DrawerTrigger'
DrawerContent.displayName = 'DrawerContent'

export { Drawer, DrawerTrigger, DrawerContent }
