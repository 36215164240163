import { type ClassValue, clsx } from 'clsx'
import { extendTailwindMerge } from 'tailwind-merge'
import { borderWidth, borderRadius } from '@vgw/multibrand-fe-styles'

export const cn = (...inputs: ClassValue[]) => {
  const twMerge = extendTailwindMerge({
    extend: {
      theme: {
        borderWidth: Object.keys(borderWidth),
        borderRadius: Object.keys(borderRadius),
      },
    },
  })

  return twMerge(clsx(inputs))
}
