import { TextField as TextFieldContainer } from './text-field'
import { Input } from './components/input'
import { Label } from './components/label'
import { MaskedInput } from './components/masked-input'

export const TextField = Object.assign(TextFieldContainer, {
  Label,
  Input,
  MaskedInput,
})
