import { forwardRef } from 'react'
import { AccordionContentProps, Content } from '@radix-ui/react-accordion'
import { cn } from '@vgw/tailwind-merger'

const AccordionContent = forwardRef<HTMLDivElement, AccordionContentProps>(
  ({ children, className, ...props }, forwardedRef) => (
    <Content
      className={cn(
        [
          'overflow-hidden',
          'bg-background',
          'data-[state=closed]:animate-accordion-up',
          'data-[state=open]:animate-accordion-down',
        ],
        className,
      )}
      ref={forwardedRef}
      {...props}
    >
      <div className="p-3">{children}</div>
    </Content>
  ),
)

AccordionContent.displayName = 'AccordionContent'

export { AccordionContent }
