import { msg } from '@lingui/core/macro'

export const COUNTRIES = {
  CA: {
    name: msg`Canada`,
    icon: 'canada',
    regions: [
      { name: msg`Alberta`, code: 'CA-AB' },
      { name: msg`British Columbia`, code: 'CA-BC' },
      { name: msg`Manitoba`, code: 'CA-MB' },
      { name: msg`New Brunswick`, code: 'CA-NB' },
      { name: msg`Northwest Territories`, code: 'CA-NT' },
      { name: msg`Nova Scotia`, code: 'CA-NS' },
      { name: msg`Nunavut`, code: 'CA-NU' },
      { name: msg`Ontario`, code: 'CA-ON' },
      { name: msg`Prince Edward Island`, code: 'CA-PE' },
      { name: msg`Quebec`, code: 'CA-QC' },
      { name: msg`Saskatchewan`, code: 'CA-SK' },
      { name: msg`Newfoundland and Labrador`, code: 'CA-NL' },
      { name: msg`Yukon`, code: 'CA-YT' },
    ],
  },
  US: {
    name: msg`United States`,
    icon: 'united_states',
    regions: [
      { name: msg`Alabama`, code: 'US-AL' },
      { name: msg`Alaska`, code: 'US-AK' },
      { name: msg`American Samoa`, code: 'US-AS' },
      { name: msg`Arkansas`, code: 'US-AR' },
      { name: msg`California`, code: 'US-CA' },
      { name: msg`Colorado`, code: 'US-CO' },
      { name: msg`Connecticut`, code: 'US-CT' },
      { name: msg`Delaware`, code: 'US-DE' },
      { name: msg`District of Columbia`, code: 'US-DC' },
      { name: msg`Florida`, code: 'US-FL' },
      { name: msg`Georgia`, code: 'US-GA' },
      { name: msg`Guam`, code: 'US-GU' },
      { name: msg`Hawaii`, code: 'US-HI' },
      { name: msg`Idaho`, code: 'US-ID' },
      { name: msg`Illinois`, code: 'US-IL' },
      { name: msg`Indiana`, code: 'US-IN' },
      { name: msg`Iowa`, code: 'US-IA' },
      { name: msg`Kansas`, code: 'US-KS' },
      { name: msg`Kentucky`, code: 'US-KY' },
      { name: msg`Louisiana`, code: 'US-LA' },
      { name: msg`Maine`, code: 'US-ME' },
      { name: msg`Maryland`, code: 'US-MD' },
      { name: msg`Massachusetts`, code: 'US-MA' },
      { name: msg`Michigan`, code: 'US-MI' },
      { name: msg`Minnesota`, code: 'US-MN' },
      { name: msg`Mississippi`, code: 'US-MS' },
      { name: msg`Missouri`, code: 'US-MO' },
      { name: msg`Montana`, code: 'US-MT' },
      { name: msg`Nebraska`, code: 'US-NE' },
      { name: msg`Nevada`, code: 'US-NV' },
      { name: msg`New Hampshire`, code: 'US-NH' },
      { name: msg`New Jersey`, code: 'US-NJ' },
      { name: msg`New Mexico`, code: 'US-NM' },
      { name: msg`New York`, code: 'US-NY' },
      { name: msg`New Carolina`, code: 'US-NC' },
      { name: msg`North Dakota`, code: 'US-ND' },
      { name: msg`Northern Mariana Islands`, code: 'US-MP' },
      { name: msg`Ohio`, code: 'US-OH' },
      { name: msg`Oklahoma`, code: 'US-OK' },
      { name: msg`Oregon`, code: 'US-OR' },
      { name: msg`Pennsylvania`, code: 'US-PA' },
      { name: msg`Puerto Rico`, code: 'US-PR' },
      { name: msg`Rhode Island`, code: 'US-RI' },
      { name: msg`South Carolina`, code: 'US-SC' },
      { name: msg`South Dakota`, code: 'US-SD' },
      { name: msg`Tennessee`, code: 'US-TN' },
      { name: msg`Texas`, code: 'US-TX' },
      { name: msg`United States Minor Outlying Islands`, code: 'US-UM' },
      { name: msg`Utah`, code: 'US-UT' },
      { name: msg`Vermont`, code: 'US-VT' },
      { name: msg`Virgin Islands`, code: 'US-VI' },
      { name: msg`Virginia`, code: 'US-VA' },
      { name: msg`Washington`, code: 'US-WA' },
      { name: msg`West Virginia`, code: 'US-WV' },
      { name: msg`Wisconsin`, code: 'US-WI' },
      { name: msg`Wyoming`, code: 'US-WY' },
    ],
  },
} as const
