import { Root } from '@radix-ui/react-toggle'
import { cn } from '@vgw/tailwind-merger'
import { SweepsCoinsLabel } from './sweeps-coins-label'
import { CoinTypeToggleIcons } from './coin-type-toggle-icons'
import { GoldCoinsLabel } from './gold-coins-label'

export type CoinType = 'GC' | 'SC'

export interface CoinTypeToggleProps {
  selectedCoinType: CoinType
  onToggle: () => void
  disabled?: boolean
  goldCoinsAmount?: number
  sweepsCoinsAmount?: number
}

const formatGCAmount = (amount: number) => {
  return formatAmount(amount, 0)
}

const formatSCAmount = (amount: number) => {
  const unitAmount = amount / 100
  return formatAmount(unitAmount, 2)
}

const formatAmount = (amount: number, decimals: number = 0) => {
  return new Intl.NumberFormat('en-US', {
    minimumFractionDigits: decimals,
    maximumFractionDigits: decimals,
  }).format(amount)
}

const CoinTypeToggle = ({
  selectedCoinType,
  onToggle,
  goldCoinsAmount = 0,
  sweepsCoinsAmount = 0,
  disabled = false,
}: CoinTypeToggleProps) => {
  const goldCoinsSelected = selectedCoinType === 'GC'
  const sweepsCoinsSelected = selectedCoinType === 'SC'

  return (
    <div className="isolate flex w-full items-center md:justify-center">
      <Root
        data-testid="coin-type-toggle"
        pressed={goldCoinsSelected}
        onPressedChange={!disabled ? onToggle : () => {}}
        className="bg-surface-neutral flex h-8 w-full rounded-full md:w-auto"
        disabled={disabled}
      >
        <CoinTypeToggleIcons
          disabled={disabled}
          goldCoinsSelected={goldCoinsSelected}
          sweepsCoinsSelected={sweepsCoinsSelected}
          className="mr-[calc(-4rem+6px)] lg:order-2 lg:ml-[-1.625rem] lg:mr-[-1.625rem]"
        />
        <GoldCoinsLabel
          goldCoinsSelected={goldCoinsSelected}
          goldCoinsAmountFormatted={formatGCAmount(goldCoinsAmount)}
          className={cn(
            'pl-[4.8rem] lg:order-1 lg:justify-end lg:pl-0 lg:pr-[2.625rem]',
            !goldCoinsSelected && 'hidden lg:flex',
          )}
        />
        <SweepsCoinsLabel
          sweepsCoinsSelected={sweepsCoinsSelected}
          sweepsCoinsAmountFormatted={formatSCAmount(sweepsCoinsAmount)}
          className={cn(
            'pl-[4.8rem] lg:order-3 lg:pl-[2.625rem]',
            !sweepsCoinsSelected && 'hidden lg:flex',
          )}
        />
      </Root>
    </div>
  )
}

export { CoinTypeToggle }
