import { Carousel as CarouselContainer } from './carousel'
import { CardContainer } from './components/card-container'
import { Card } from './components/card'
import { IndicatorsContainer } from './components/indicators-container'
import { Indicator } from './components/indicator'
import { Container } from './components/container'
import { Arrow } from './components/arrow'

export { usePrevNextButtons } from './hooks/usePrevNextButtons'
export { useDotButton } from './hooks/useDotButton'
export { useCarousel } from './hooks/useCarousel'
export { WheelGesturesPlugin } from './plugins/wheel-gestures-plugin'
export { AutoPlay } from './plugins/autoplay-plugin'

export const Carousel = Object.assign(CarouselContainer, {
  Arrow,
  CardContainer,
  Card,
  IndicatorsContainer,
  Indicator,
  Container,
})
