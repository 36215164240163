import { useEffect, useState } from 'react'
import type { Duration } from 'date-fns'
import { intervalToDuration } from 'date-fns/intervalToDuration'

const getDuration = (until: Date): Duration => {
  if (until.getTime() <= new Date().getTime()) {
    return {}
  }
  return intervalToDuration({
    start: new Date(),
    end: until,
  })
}

export const useCountdownDuration = (until: Date) => {
  const [duration, setDuration] = useState<Duration>(getDuration(until))

  useEffect(() => {
    const refreshCounter = () => {
      setDuration(getDuration(until))
    }

    refreshCounter()
    const intervalId = setInterval(refreshCounter, 1000)

    return () => {
      clearInterval(intervalId)
    }
  }, [setDuration, until])

  return duration
}
