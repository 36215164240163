import { FormSectionContext } from './form-section.context'
import { FormSectionSize } from './types'
import { HTMLAttributes } from 'react'
import { cn } from '@vgw/tailwind-merger'

export interface FormSectionProps extends HTMLAttributes<HTMLSpanElement> {
  size?: FormSectionSize
}

const FormSection = ({
  size = 'sm',
  className,
  ...props
}: FormSectionProps) => (
  <FormSectionContext.Provider
    value={{
      size,
    }}
  >
    <div className={cn('flex flex-col gap-1', className)} {...props} />
  </FormSectionContext.Provider>
)

FormSection.displayName = 'FormSection'

export { FormSection }
