import { useParams } from 'react-router-dom'
import { useCategoryQuery } from '@/pages/category/use-category-query'
import { Trans } from '@lingui/react/macro'
import { useJackpotsQuery } from '@/features/jackpots/use-jackpots-query'
import { getGamesWithJackpotValue } from '@/features/jackpots/get-games-with-jackpot-value'
import { useSelectedCoinType } from '@/use-selected-coin-type'
import { useMemo } from 'react'
import { LayoutItemCard } from '@/features/layout/components/layout-item-card'

function CategoryPage() {
  const { categoryName } = useParams<{ categoryName: string }>()
  const { isLoading, category, error } = useCategoryQuery(categoryName || '')
  const { data: jackpots } = useJackpotsQuery()
  const selectedCoinType = useSelectedCoinType()

  const games = useMemo(() => {
    if (!category?.games) {
      return []
    }
    return getGamesWithJackpotValue(category.games, selectedCoinType, jackpots)
  }, [category?.games, selectedCoinType, jackpots])

  if (isLoading)
    return (
      <span>
        <Trans>Loading</Trans>
      </span>
    )

  if (error)
    return (
      <p>
        <Trans>An error has occurred</Trans>
      </p>
    )

  if (!category) {
    return null
  }

  return (
    <section className="flex min-h-dvh flex-col items-start gap-2 px-2 py-0 sm:px-4 md:px-6 lg:py-4">
      <header className="font-display text-xl font-bold text-category-title md:text-2xl">
        {category.name}
      </header>
      <div className="flex flex-wrap gap-2 sm:gap-4 lg:gap-6">
        {games.map((game) => (
          <LayoutItemCard
            key={game.gameId}
            name={game.name}
            imageUrl={game.thumbnails.image2x3}
            gameId={game.gameId}
            launchUrl={game.launchUrl}
            variant="portrait"
            jackpots={game.jackpots}
            className="h-[173px] md:h-[222px] lg:h-[258px] xl:h-[315px] 2xl:h-[324px]"
          />
        ))}
      </div>
    </section>
  )
}

export { CategoryPage }
