import React from 'react'
import { cn } from '@vgw/tailwind-merger'
import { useTextFieldContext } from '../text-field.context'

export interface LabelProps {
  htmlFor: string
  children: React.ReactNode
  error?: boolean
  manuallyFocused?: boolean
}

const Label: React.FC<LabelProps> = ({ htmlFor, children }) => {
  const { focused, manuallyFocused, hasValue, disabled, error, size } =
    useTextFieldContext()
  const isFocused = focused || manuallyFocused

  return (
    <label
      htmlFor={htmlFor}
      className={cn(
        'text-content-placeholder pointer-events-none absolute transform transition-all',
        isFocused && 'text-form-input-content-label-active',
        size === 'sm' &&
          (isFocused || hasValue
            ? 'top-1 text-[10px] font-normal leading-[14px]'
            : 'top-1/2 -translate-y-1/2 transform text-base'),
        size === 'md' &&
          (isFocused || hasValue
            ? 'text-[11px] font-normal leading-[15px]'
            : 'top-1/2 -translate-y-1/2 transform text-[16px] leading-[24px]'),
        error && 'text-status-error',
        disabled &&
          (hasValue ? 'text-content-placeholder' : 'text-content-disabled'),
      )}
    >
      {children}
    </label>
  )
}

Label.displayName = 'TextField.Label'

export { Label }
