import { ReactNode } from 'react'

interface ImageHeaderProps {
  name: string
  image: string
  renderPills: () => ReactNode
}

const ImageHeader = ({ name, image, renderPills }: ImageHeaderProps) => {
  return (
    <div className="relative flex w-full">
      <img
        alt={name}
        src={image}
        className="aspect-video w-full object-cover"
      />

      <div className="absolute right-1.5 top-1 flex flex-wrap content-center items-center gap-1">
        {renderPills()}
      </div>
    </div>
  )
}

export { ImageHeader }
