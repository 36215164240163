import { ReactNode } from 'react'

interface TextHeaderProps {
  name: string
  renderPills: () => ReactNode
}

const TextHeader = ({ name, renderPills }: TextHeaderProps) => {
  return (
    <div className="flex flex-wrap content-start items-start justify-between gap-2 self-stretch">
      <span className="text-lg font-bold leading-7 tracking-normal text-content-base">
        {name}
      </span>
      <span className="flex flex-wrap content-center items-center gap-1">
        {renderPills()}
      </span>
    </div>
  )
}

export { TextHeader }
