import { cn } from '@vgw/tailwind-merger'
import { forwardRef, InputHTMLAttributes } from 'react'
import { useTextFieldContext } from '../text-field.context'

export interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  error?: boolean
}

const Input = forwardRef<HTMLInputElement, InputProps>(
  ({ className, onFocus, onBlur, ...props }, ref) => {
    const { setFocused, setHasValue, defaultValue, value, size, disabled } =
      useTextFieldContext()

    const handleFocus = (e: React.FocusEvent<HTMLInputElement>) => {
      if (onFocus) {
        onFocus(e)
      }
      setFocused(true)
    }

    const handleBlur = (e: React.FocusEvent<HTMLInputElement>) => {
      if (onBlur) {
        onBlur(e)
      }
      setFocused(false)
      setHasValue(!!e.target.value)
    }

    return (
      <input
        ref={ref}
        className={cn(
          'w-full bg-transparent pb-1 outline-none read-only:cursor-auto',
          size === 'sm' && 'pt-3 text-sm font-normal leading-5',
          size === 'md' && 'pt-4 text-base font-normal leading-6',
          className,
        )}
        onFocus={handleFocus}
        onBlur={handleBlur}
        defaultValue={defaultValue}
        value={value}
        disabled={disabled}
        {...props}
      />
    )
  },
)

Input.displayName = 'TextField.Input'

export { Input }
