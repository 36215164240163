import { Label } from './label'
import { CoinType } from '@/selected-coin-type-context'
import { CustomIcon } from '@vgw/multibrand-fe-icon'
import clsx from 'clsx'
import { Trans } from '@lingui/react/macro'

interface CoinsProperties {
  selectedCoinType: CoinType
  minPlay?: string
}

function MinimalPlayAmount({ minPlay, selectedCoinType }: CoinsProperties) {
  return (
    <>
      <div
        data-testid="minimal-play-amount"
        className="flex items-center gap-2 rounded-[0.375rem]"
      >
        <CustomIcon
          className="h-5 w-5"
          name={
            selectedCoinType === 'GC'
              ? 'coloured:gold-coin'
              : 'coloured:sweeps-coin'
          }
        />
        <span
          className={clsx('text-base font-bold', {
            'text-gold-coins-base': selectedCoinType === 'GC',
            'text-sweeps-coins-base': selectedCoinType === 'SC',
          })}
        >
          {minPlay}
        </span>
      </div>
      <Label>
        <Trans>Minimal play</Trans>
      </Label>
    </>
  )
}

export { MinimalPlayAmount }
