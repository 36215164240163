import { ClaimLimit } from '@/features/store/claim-limit'
import { TimeLimit } from '@/features/store/time-limit'

interface PillsProps {
  validUntil?: Date
  claim: {
    limit?: number
    count: number
  }
}

const Pills = ({ claim, validUntil }: PillsProps) => {
  return (
    <>
      {claim.limit && <ClaimLimit limit={claim.limit} count={claim.count} />}
      {validUntil && <TimeLimit validUntil={validUntil} />}
    </>
  )
}

export { Pills }
