export const SkeletonLobby = () => {
  const viewPortWidthInRem = Math.min(window.innerWidth, 1536) / 16
  const viewportHeightInRem = window.innerHeight / 16
  const tileWidthInRemPlusGap =
    determineTileWidthForViewport(window.innerWidth) + 0.75
  const numberOfColumns = Math.floor(viewPortWidthInRem / tileWidthInRemPlusGap)

  const tileHeightInRemPlusGapPlusLabel = 9 * (4 / 3) + 0.75 + 1.25 // This could be more accurate per viewport, but it's an optimisation
  const numberOfRows = Math.floor(
    viewportHeightInRem / tileHeightInRemPlusGapPlusLabel,
  )

  return (
    <div className="p-5" data-testid="loader">
      <div className="h-6 w-1/2 animate-pulse bg-skeleton md:w-1/4"></div>
      <div className="mb-10 mt-3 flex justify-center gap-3 lg:justify-between">
        {[...Array(numberOfColumns)].map((_, idx) => (
          <GenericGameTileBig key={`big-tile-${idx}`} />
        ))}
      </div>
      {[...Array(numberOfRows)].map((_, idx) => (
        <div
          className="mb-10 mt-3 flex flex-col justify-center gap-3"
          key={`games-row-${idx}`}
        >
          <div className="h-6 w-1/2 animate-pulse bg-skeleton md:w-1/4"></div>
          <div className="flex gap-3 lg:justify-between">
            {[...Array(numberOfColumns)].map((_, idx) => (
              <GenericGameTile key={`tile-${idx}`} />
            ))}
          </div>
        </div>
      ))}
    </div>
  )
}

// All rem dimensions below are based on their respective aspect ratios (4/3 or 1/1.7).
const GenericGameTileBig = () => {
  return (
    <div className="h-[10.2rem] w-[6rem] animate-pulse bg-skeleton md:h-[15.3rem] md:w-[9rem]"></div>
  )
}

const GenericGameTile = () => {
  return (
    <div className="aspect-[4/3] h-auto w-[9rem] animate-pulse bg-skeleton sm:w-[6.5rem] md:w-[6rem] lg:w-[10.125rem]"></div>
  )
}

const determineTileWidthForViewport = (viewportWidth: number): number => {
  let tileWidth
  switch (true) {
    case viewportWidth < 480:
      tileWidth = 6.5
      break
    case viewportWidth >= 480 && viewportWidth < 768:
      tileWidth = 7.5
      break
    case viewportWidth >= 768 && viewportWidth < 1024:
      tileWidth = 6
      break
    default:
      tileWidth = 9
  }
  return tileWidth
}
