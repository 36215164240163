import * as React from 'react'
import { IconNames, Icons } from './custom-vectors'
import { cva, VariantProps } from 'class-variance-authority'
import { cn } from '@vgw/tailwind-merger'

const iconVariants = cva('', {
  variants: {
    size: {
      xs: 'h-3.5 w-3.5',
      sm: 'h-4 w-4',
      md: 'h-5 w-5',
      lg: 'h-6 w-6',
      xl: 'h-7 w-7',
      '2xl': 'h-8 w-8',
      '3xl': 'h-9 w-9',
      '4xl': 'h-10 w-10',
      '5xl': 'h-11 w-11',
      '6xl': 'h-12 w-12',
    },
  },
})

export type IconSizes =
  | 'xs'
  | 'sm'
  | 'md'
  | 'lg'
  | 'xl'
  | '2xl'
  | '3xl'
  | '4xl'
  | '5xl'
  | '6xl'

interface IconProperties extends VariantProps<typeof iconVariants> {
  name: IconNames
  hoverName?: IconNames
  title?: string
  size?: IconSizes
  className?: string
  wrapperClassName?: string
  fill?: string
  role?: string
  viewBox?: string
}

export const CustomIcon: React.FC<IconProperties> = ({
  name,
  hoverName,
  title,
  size = 'md',
  wrapperClassName,
  className,
  fill = 'fill-content-base',
  ...props
}: IconProperties) => {
  return (
    <div
      className={cn(
        iconVariants({ size, className: wrapperClassName }),
        'group flex justify-center',
      )}
    >
      <svg
        className={cn(
          iconVariants({ className }),
          'h-full w-auto',
          fill,
          hoverName ? 'group-hover:hidden' : '',
        )}
        viewBox={Icons[name].viewBox}
        {...props}
      >
        {title && <title>{title}</title>}
        {Icons[name].content}
      </svg>
      {hoverName && (
        <svg
          className={cn(
            iconVariants({ className }),
            'h-full w-auto',
            fill,
            'hidden group-hover:block',
          )}
          viewBox={Icons[hoverName].viewBox}
          {...props}
        >
          {title && <title>{title}</title>}
          {Icons[hoverName].content}
        </svg>
      )}
    </div>
  )
}
