export const calculateNextTickerAmount = (
  currentValue: number,
  target: number,
) => {
  if (target < currentValue) {
    return target
  }
  const difference = Math.max(0, target - currentValue)
  const increment = Math.max(100, Math.floor(difference / 10))
  const newAmount = currentValue + increment
  return Math.min(target, newAmount)
}
