import { Button } from '@vgw/multibrand-fe-button'
import { CoinAmount } from './coin-amount'
import { CoinsState } from '@/features/coins-state/types'
import { Trans } from '@lingui/react/macro'

export interface UserSidebarInformationProps {
  userName?: string
  userEmail?: string
  coinAmounts?: CoinsState
}

export const UserSidebarInformation = ({
  userName,
  userEmail,
  coinAmounts,
}: UserSidebarInformationProps) => {
  return (
    <div className="flex w-full flex-col bg-surface-secondary p-6">
      <div className="mb-3 flex">
        <div className="mr-3 flex h-[80px] w-[80px] shrink-0 overflow-hidden rounded border border-transparent bg-[#D9D1F8]">
          {/* TODO: replace with user profile image when API ready */}
          {/* <img     
            alt="User Avatar"
            className="h-full w-full object-cover"/> */}
        </div>
        <div>
          <h2 className="mb-0.5 text-lg font-bold leading-[26px] text-content-base">
            {userName}
          </h2>
          <p className="mb-3 overflow-hidden text-xs font-normal leading-4 text-content-muted">
            {userEmail}
          </p>
          <Button variant="outline" size="sm">
            <Trans>Manage Account</Trans>
          </Button>
        </div>
      </div>

      <div className="mb-3 flex flex-row justify-center gap-2">
        <CoinAmount coinType="GC" amount={coinAmounts?.goldCoinsAmount} />
        <CoinAmount coinType="SC" amount={coinAmounts?.sweepsCoinsAmount} />
      </div>

      <div className="flex flex-row justify-center gap-2">
        <Button className="flex-1" variant="primary" size="md">
          <Trans>Get Coins</Trans>
        </Button>
        <Button className="flex-1" variant="secondary" size="md">
          <Trans>Redeem</Trans>
        </Button>
      </div>
    </div>
  )
}
