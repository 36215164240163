import { Carousel } from '@vgw/multibrand-fe-carousel'

interface NavigationArrowsProps {
  leftNavigationDisabled: boolean
  onLeftNavigation: () => void
  rightNavigationDisabled: boolean
  onRightNavigation: () => void
  isSingleCard: boolean
}

const NavigationArrows = ({
  leftNavigationDisabled,
  onLeftNavigation,
  rightNavigationDisabled,
  onRightNavigation,
  isSingleCard,
}: NavigationArrowsProps) => {
  if (isSingleCard) return null

  return (
    <div className="hidden items-start gap-4 self-end md:inline-flex">
      <Carousel.Arrow
        onClick={onLeftNavigation}
        disabled={leftNavigationDisabled}
        data-testid="left-arrow"
        arrow="left"
      />
      <Carousel.Arrow
        onClick={onRightNavigation}
        disabled={rightNavigationDisabled}
        data-testid="right-arrow"
        arrow="right"
      />
    </div>
  )
}

export { NavigationArrows }
