import { cn } from '@vgw/tailwind-merger'
import { Carousel } from '@vgw/multibrand-fe-carousel'
import { LayoutProps } from '@/features/layout/layout-factory'
import { LayoutDoubleCard } from '@/features/layout/components/layout-double-card'
import { LayoutItemCard } from '@/features/layout/components/layout-item-card'

export const LayoutStackedThree = ({ games }: LayoutProps) => (
  <>
    <LayoutDoubleCard games={games.slice(0, 2)} />
    {games.slice(2, 3).map((game, idx) => (
      <Carousel.Card
        key={game.gameId}
        index={idx}
        className={cn([
          'mr-2',
          'h-[228px]',

          'sm:mr-4',
          'sm:h-[236px]',

          'lg:mr-6',
          'lg:h-[286px]',

          'xl:h-[332px]',

          '2xl:h-[404px]',
        ])}
      >
        <LayoutItemCard
          name={game.name}
          imageUrl={game.thumbnails.image1x1}
          gameId={game.gameId}
          launchUrl={game.launchUrl}
          variant="portrait"
          jackpots={game.jackpots}
        />
      </Carousel.Card>
    ))}
    <LayoutDoubleCard games={games.slice(3)} />
  </>
)
