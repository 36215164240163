import { forwardRef, ReactNode, useContext } from 'react'
import { cva, type VariantProps } from 'class-variance-authority'
import {
  SelectTriggerProps as PrimitiveSelectTriggerProps,
  Trigger,
  Value,
} from '@radix-ui/react-select'
import { cn } from '@vgw/tailwind-merger'
import { SelectLabel } from './select-label'
import { SelectInput } from './select-input'
import { SelectContext } from './select.context'

const selectTriggerVariants = cva(
  cn([
    'group',
    'relative',
    'w-full',

    'flex',
    'items-start',
    'justify-between',

    'bg-form-input-surface',
    'rounded-radius-form',
    'text-form-input-content-base',

    'before:absolute',
    'before:bottom-0',
    'before:left-0',
    'before:right-0',

    'before:border-b',
    'before:border-solid',

    'before:transition-border-color',
    'before:duration-300',

    'before:pointer-events-none',

    'overflow-hidden',

    'outline-none',
    'focus-visible:ring-2',
    'focus-visible:ring-form-input-border-focus',
    'focus-visible:ring-offset-2',
  ]),
  {
    variants: {
      size: {
        sm: ['h-[46px]', 'px-2', 'py-1.5', 'text-sm', 'leading-5'],
        md: ['h-14', 'px-3', 'py-2', 'text-base'],
      },
    },
    defaultVariants: {
      size: 'sm',
    },
  },
)

const getErrorStyles = (error: boolean) => {
  // eslint-disable-next-line lingui/no-unlocalized-strings
  return error ? 'before:border-status-error before:border-b-2' : ''
}

// Function to handle disabled styling
const getDisabledStyles = (disabled: boolean) => {
  return disabled
    ? // eslint-disable-next-line lingui/no-unlocalized-strings
      'bg-disabled pointer-events-none before:border-disabled before:border-b'
    : ''
}

// Function to handle focused styling when there's no error
const getFocusedStyles = ({
  focused,
  error,
}: {
  focused: boolean
  error: boolean
}) => {
  return focused && !error
    ? // eslint-disable-next-line lingui/no-unlocalized-strings
      'before:border-form-input-content-label-active before:border-b-2'
    : ''
}

export interface SelectTriggerProps
  extends VariantProps<typeof selectTriggerVariants>,
    PrimitiveSelectTriggerProps {
  startIcon?: ReactNode
}

const SelectTrigger = forwardRef<HTMLButtonElement, SelectTriggerProps>(
  ({ className, children, startIcon, ...props }, ref) => {
    const {
      size,
      error,
      value,
      disabled,
      focused,
      hasLabel,
      placeholder,
      showValue,
    } = useContext(SelectContext)

    return (
      <Trigger
        ref={ref}
        className={cn(
          selectTriggerVariants({ size, className }),
          getErrorStyles(error),
          getDisabledStyles(disabled),
          getFocusedStyles({ focused, error }),
          !focused && !error && 'hover:before:border-form-input-border-hover',
        )}
        {...props}
      >
        <div className={cn('flex h-full', { 'gap-2': showValue })}>
          {startIcon && (
            <div
              className={cn({ 'mt-3': size === 'md', 'mt-2': size === 'sm' })}
            >
              {startIcon}
            </div>
          )}
          <div
            className={cn(
              'flex h-full flex-col items-start',
              !hasLabel && 'items-center',
            )}
          >
            <SelectLabel />
            <SelectInput>
              {showValue && value && <Value placeholder={placeholder} />}
            </SelectInput>
          </div>
        </div>
        {children}
      </Trigger>
    )
  },
)

SelectTrigger.displayName = 'SelectTrigger'

export { SelectTrigger }
