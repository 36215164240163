import { cn } from '@vgw/tailwind-merger'
import { Icon } from './icon'
import { CustomIcon } from '@vgw/multibrand-fe-icon'
import { useLingui } from '@lingui/react/macro'

const getBackgroundColor = (goldCoinsSelected: boolean, disabled: boolean) => {
  if (disabled) {
    return goldCoinsSelected ? '@xl:bg-surface-neutral' : 'bg-surface-neutral'
  }
  return goldCoinsSelected ? 'bg-gold-coins-base' : 'bg-sweeps-coins-base'
}

interface CoinTypeToggleIconsProps {
  disabled: boolean
  goldCoinsSelected: boolean
  sweepsCoinsSelected: boolean
  className?: string
}

export function CoinTypeToggleIcons({
  disabled,
  goldCoinsSelected,
  sweepsCoinsSelected,
  className,
}: CoinTypeToggleIconsProps) {
  const { t } = useLingui()

  return (
    <span
      className={cn(
        'z-10 flex rounded-full',
        getBackgroundColor(goldCoinsSelected, disabled),
        className,
      )}
    >
      <Icon
        className={goldCoinsSelected ? '' : 'opacity-40'}
        data-testid="coloured:gold-coin"
        data-selected={goldCoinsSelected}
      >
        <CustomIcon
          name="coloured:gold-coin"
          size={goldCoinsSelected ? '2xl' : 'lg'}
          title={t`Gold coins`}
        />
      </Icon>
      <Icon
        className={`ml-[-5px] mr-[-1px] ${sweepsCoinsSelected ? '' : 'opacity-40'}`}
        data-testid="coloured:sweeps-coin"
        data-selected={sweepsCoinsSelected}
      >
        <CustomIcon
          name="coloured:sweeps-coin"
          size={sweepsCoinsSelected ? '2xl' : 'lg'}
          title={t`Sweeps coins`}
        />
      </Icon>
    </span>
  )
}
