import Logo from '@/features/header/logo'
import { Button } from '@vgw/multibrand-fe-button'
import { cn } from '@vgw/tailwind-merger'
import { Link } from 'react-router-dom'
import { useTracking } from '@vgw/multibrand-fe-tracking'
import { UserHeader, UserHeaderProps } from './user-header/user-header'
import { Trans } from '@lingui/react/macro'
import { SubMenuNavigation } from './sub-menu-navigation/sub-menu-navigation'
import { IconButton } from '@vgw/multibrand-fe-icon-button'

interface UserProps {
  login: () => Promise<void>
  logout: () => Promise<void>
  userName?: string
  userEmail?: string
  isUserLoggedIn: boolean
}

export interface HeaderProps {
  user: UserProps
  className: string
  coinTypeInfo: UserHeaderProps['coinTypeInfo']
  setIsStoreOpen: (isStoreOpen: boolean) => void
  isInGame?: boolean
  renderSubNavigation?: boolean
}

const Header = ({
  user: { isUserLoggedIn, userName, logout, login, userEmail },
  className,
  coinTypeInfo,
  setIsStoreOpen,
  isInGame,
  renderSubNavigation,
}: HeaderProps) => {
  const { track } = useTracking()

  return (
    <>
      <header
        className={cn(
          'z-20 flex w-full flex-col items-center bg-navigation-surface shadow-header md:bg-surface-base',
          {
            'bg-navigation-surface md:bg-navigation-surface': isInGame,
          },
          className,
        )}
        data-testid="page-header"
      >
        <div className="flex w-full max-w-screen-2xl items-center justify-between gap-2 px-2 py-2.5 sm:px-4 md:px-6">
          {!isInGame && (
            <Link data-testid="logo" to="/">
              <Logo />
            </Link>
          )}
          {isInGame && (
            <IconButton asChild variant="outline" icon="arrow-left" size="sm">
              <Link to="/" />
            </IconButton>
          )}
          {!isUserLoggedIn && (
            <Button
              variant="primary"
              className="h-[36px]"
              size="sm"
              onClick={() => {
                track('Header:LoginStarted', { sendImmediate: true })
                void login() // Log in is based on redirects, that's why we don't care about the Promise here
              }}
            >
              <Trans>Sign In</Trans>
            </Button>
          )}
          {isUserLoggedIn && (
            <UserHeader
              setIsStoreOpen={setIsStoreOpen}
              logout={logout}
              username={userName}
              userEmail={userEmail}
              coinTypeInfo={coinTypeInfo}
              isInGame={isInGame}
            />
          )}
        </div>
      </header>
      {renderSubNavigation && <SubMenuNavigation />}
    </>
  )
}

export default Header
