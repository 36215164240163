import { BrandFeatures, getBrandConfig } from '@/config/config'
import { useGalleryQuery } from '@/features/game-api/use-gallery-query'

export const useBrandFeatures = (): BrandFeatures => {
  const { data: gallery } = useGalleryQuery()
  const brandConfig = getBrandConfig()

  return {
    ...brandConfig.features,
    isSweepsCoinsEnabled: gallery?.coinMode === 'GC_SC',
  }
}
