import { Game } from '@/features/game-api/use-gallery-query'
import { Jackpots } from '@/features/jackpots/use-jackpots-query'
import { CoinType } from '@/selected-coin-type-context'
import { sortByIndex } from '@/features/jackpots/sort-by-index'

export const getGamesWithJackpotValue = (
  games: Game[],
  selectedCoinType: CoinType,
  jackpots?: Jackpots,
) =>
  games
    .map(({ gameId, ...game }) => {
      const jackpotsValues = jackpots?.[gameId]?.jackpots
        ?.filter((jackpot) => jackpot.coinType === selectedCoinType)
        .map((jackpot) => ({
          name: jackpot.name,
          amount: Number(jackpot.value),
        }))

      return {
        ...game,
        gameId,
        jackpots: jackpotsValues || [],
      }
    })
    .sort(sortByIndex)
