import { JSX, useEffect } from 'react'

interface SimpleHeroBannerBaseProps {
  card: SimpleHeroBannerBaseCard
  onCardEntersViewport?: (cardIndex: number) => void
}

export interface SimpleHeroBannerBaseCard {
  MainElement: (props: { className: string }) => JSX.Element
}

export const SimpleHeroBannerBase = ({
  card: { MainElement },
  onCardEntersViewport,
}: SimpleHeroBannerBaseProps) => {
  useEffect(() => {
    onCardEntersViewport?.(0)
  }, [onCardEntersViewport])

  return <MainElement className="aspect-video w-full object-cover" />
}
