import { IconButton, IconButtonProps } from '@vgw/multibrand-fe-icon-button'
import { cn } from '@vgw/tailwind-merger'

interface MoreInfoButtonProps extends Omit<IconButtonProps, 'icon'> {}

function MoreInfoButton({ className, ...props }: MoreInfoButtonProps) {
  return (
    <>
      <IconButton
        icon="dots-vertical"
        className={cn(
          'fill-white-alpha-1000 bg-overlay-base hover:bg-black-alpha-500 active:bg-black-alpha-600 focus:button-border-focus',
          className,
        )}
        {...props}
      />
    </>
  )
}

export { MoreInfoButton }
