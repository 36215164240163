import { ButtonHTMLAttributes, forwardRef } from 'react'
import {
  AccordionTriggerProps as PrimitiveAccordionTriggerProps,
  Header,
  Trigger,
} from '@radix-ui/react-accordion'
import { cn } from '@vgw/tailwind-merger'
import { CustomIcon } from '@vgw/multibrand-fe-icon'

interface AccordionTriggerProps
  extends PrimitiveAccordionTriggerProps,
    ButtonHTMLAttributes<HTMLButtonElement> {}

const AccordionTrigger = forwardRef<HTMLButtonElement, AccordionTriggerProps>(
  ({ children, className, ...props }, forwardedRef) => (
    <Header>
      <Trigger
        className={cn(
          'group',

          'flex',
          'items-center',
          'justify-between',
          'gap-4',

          'w-full',
          'py-2',
          'px-3',

          'rounded',

          'bg-accordion-button-surface-default',
          'text-accordion-button-content',

          'text-base',
          'font-normal',

          'hover:bg-accordion-button-surface-hover',

          'active:bg-accordion-button-surface-pressed',

          'focus-visible:outline-2',
          'focus-visible:outline-button-border-focus',
          'focus-visible:outline-offset-2',
          'focus-visible:bg-accordion-button-surface-pressed',
          'outline-offset-background',

          'disabled:bg-disabled',
          'disabled:text-content-disabled',

          'ease-[cubic-bezier(0.87,_0,_0.13,_1)]',
          'transition-transform',
          'duration-300',
          className,
        )}
        {...props}
        ref={forwardedRef}
      >
        {children}
        <CustomIcon
          className={cn(
            'ease-[cubic-bezier(0.87,_0,_0.13,_1)]',
            'transition-transform',
            'duration-300',
            'group-data-[state=open]:rotate-180',
            'group-data-[disabled]:fill-content-disabled',
          )}
          name="chevron-down"
          size="sm"
          aria-hidden
        />
      </Trigger>
    </Header>
  ),
)

AccordionTrigger.displayName = 'AccordionTrigger'

export { AccordionTrigger }
