import { getOidc } from './oidc'

/**
 * @example
 *
 * Add auth for client
 *
 * ```ts
 * import { client } from '@vgw/api-gap-gallery-view'
 * client.interceptors.request.use(AuthInterceptor)
 * ```
 *
 * Opt-out request from Auth
 *
 * @example
 * ```ts
 * import { client } from '@vgw/api-gap-gallery-view'
 * client.interceptors.request.eject(AuthInterceptor)
 * ```
 */
export const AuthInterceptor = async (request: Request): Promise<Request> => {
  const oidc = await getOidc()

  if (oidc.isUserLoggedIn) {
    const headers = request.headers
    headers.set('Authorization', `Bearer ${oidc.getTokens().accessToken}`)
  }

  return request
}
