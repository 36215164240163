import { ReactNode } from 'react'
import {
  DialogProps as PrimitiveDialogProps,
  Portal,
  Root,
  Trigger,
} from '@radix-ui/react-dialog'

export interface DialogProps extends PrimitiveDialogProps {
  children: ReactNode
  trigger?: ReactNode
}

const Dialog = ({ children, trigger, ...props }: DialogProps) => (
  <Root {...props}>
    {trigger && <Trigger asChild>{trigger}</Trigger>}
    <Portal>{children}</Portal>
  </Root>
)

Dialog.displayName = 'Dialog'

export { Dialog }
