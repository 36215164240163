import Header from '@/features/header/header'
import Footer from '@/features/footer/footer'
import { Outlet, useLocation } from 'react-router-dom'
import { useOidc } from '@/features/oidc/oidc'
import { useCoinsStateQuery } from '@/features/coins-state/use-coins-state-query'
import { useContext, useState, useEffect } from 'react'
import { SelectedCoinTypeContext } from '@/selected-coin-type-context'
import { GAME_PLAY_PATH } from '@/config/paths'
import MobileNavbar, {
  type MobileNavbarItem,
} from '@vgw/multibrand-fe-mobile-navbar'
import { useBrandFeatures } from '@/lib/use-brand-features'
import { ScrollRestoration, useNavigate } from 'react-router-dom'
import { offersOptions } from '@/apis/pam-store-api'
import { useQuery } from '@tanstack/react-query'
import { Store } from '@/pages/store/store'
import { setUTMs } from '@vgw/mar-utm-tracker'

interface RootProps {
  mainClassName?: string
  renderHeader?: boolean
  renderFooter?: boolean
  renderBackground?: boolean
  renderSubNavigation?: boolean
  isInGame?: boolean
}

export const Root = ({
  mainClassName,
  renderHeader = true,
  renderFooter = true,
  renderBackground = false,
  renderSubNavigation = true,
  isInGame = false,
}: RootProps) => {
  const { data: offersData } = useQuery({
    ...offersOptions(),
  })
  const navigate = useNavigate()
  const { isUserLoggedIn, login, logout, oidcTokens } = useOidc()
  const {
    data: coinsState,
    error,
    isLoading,
  } = useCoinsStateQuery(isUserLoggedIn)
  const { selectedCoinType, setSelectedCoinType } = useContext(
    SelectedCoinTypeContext,
  )
  const [isStoreOpen, setIsStoreOpen] = useState(false)
  const { isSweepsCoinsEnabled } = useBrandFeatures()
  const handleChange = (value: MobileNavbarItem) => {
    if (value === 'home') {
      navigate('/', { preventScrollReset: true })
    }

    if (value === 'store') {
      setIsStoreOpen(true)
    }
  }

  const location = useLocation()

  useEffect(() => {
    // set UTMs on every client navigations
    setUTMs()
  }, [location])

  return (
    <div
      className="flex min-h-screen w-full flex-col items-center bg-surface-base"
      data-testid="root-layout"
    >
      <ScrollRestoration />
      {renderHeader && (
        <Header
          user={{
            userName: oidcTokens?.decodedIdToken.name as string,
            // TODO: replace with email from token,
            // for now it is preferred_username which equals to email in some cases
            userEmail: oidcTokens?.decodedIdToken.preferred_username as string,
            isUserLoggedIn,
            login: async () =>
              login && login({ doesCurrentHrefRequiresAuth: false }),
            logout: async () => logout && logout({ redirectTo: 'home' }),
          }}
          coinTypeInfo={{
            amounts: coinsState,
            error,
            isLoading,
            selectedCoinType: selectedCoinType,
            gamePlayPath: GAME_PLAY_PATH,
            onCoinTypeChange: () =>
              setSelectedCoinType(selectedCoinType === 'GC' ? 'SC' : 'GC'),
            isSweepsCoinsEnabled,
          }}
          setIsStoreOpen={setIsStoreOpen}
          isInGame={isInGame}
          renderSubNavigation={renderSubNavigation}
          className="sticky inset-x-0 top-0"
        />
      )}
      <main
        className={[
          'flex w-full flex-1 flex-col items-center bg-surface-secondary',
          renderBackground
            ? 'bg-page-small-background bg-contain bg-top bg-no-repeat'
            : '',
          mainClassName,
        ].join(' ')}
      >
        <div
          className={[
            'flex w-full max-w-screen-2xl items-center',
            renderBackground ? 'grow flex-col' : '',
          ].join(' ')}
        >
          <Outlet />
        </div>
      </main>
      {renderFooter && <Footer isSweepsCoinsEnabled={isSweepsCoinsEnabled} />}
      <MobileNavbar
        onChange={handleChange}
        itemsWithIndicator={
          offersData?.specialOfferSets?.length ? ['store'] : []
        }
      />
      <Store
        regularStoreItems={offersData?.regularStoreItems || []}
        specialOfferSets={offersData?.specialOfferSets || []}
        isStoreOpen={isStoreOpen}
        setIsStoreOpen={setIsStoreOpen}
      />
    </div>
  )
}

export default Root
