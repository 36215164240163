import { Link } from 'react-router-dom'
import { Trans } from '@lingui/react/macro'

export const LicenseDetails = () => {
  return (
    <p className="max-w-screen-xl text-content-muted lg:text-center xl:m-auto">
      <Trans>
        Copyright © 2002-2024 VGW Group. All rights reserved. Chumba Casino is
        owned and operated by VGW Malta Limited. All payments are processed by
        VGW Malta Limited. The sweepstakes promotions and prizes offered at
        Chumba Casino are operated by VGW Games Limited. The registered address
        of VGW Games Limited is Trident Park, Notabile Gardens, No. 6, Level 3,
        Central Business District, Mdina Road, Zone 2 Birkirkara, CBD2010,
        Malta. VGW Games Limited is regulated and licensed by Malta Gaming
        Authority (MGA). This public regulatory authority is responsible for the
        supervision of all kinds of gaming activities that are based in Malta.
        VGW Games Limited holds licence{' '}
        <Link
          className="font-bold underline"
          to="https://authorisation.mga.org.mt/verification.aspx?lang=EN&company=4debab56-21d5-49fd-96c5-1c2b5500dbb4&details=1"
        >
          <span>MGA/B2C/188/2010</span>
        </Link>{' '}
        issued on the 14th August 2017.
      </Trans>
    </p>
  )
}
