import { ReactNode } from 'react'
import { cn } from '@vgw/tailwind-merger'
import { cva, VariantProps } from 'class-variance-authority'

const chipVariants = cva('text-sm font-normal leading-5', {
  variants: {
    variant: {
      chip: 'bg-overlay-dark text-content-inverse rounded-full px-2 py-0.5',
      tag: 'bg-status-success rounded border-b border-b-green-800 px-1 font-bold text-white',
    },
  },
  defaultVariants: {
    variant: 'chip',
  },
})

interface ChipProps extends VariantProps<typeof chipVariants> {
  children: ReactNode
  className?: string
}

const Chip = ({ className, variant = 'chip', ...props }: ChipProps) => {
  return <div className={cn(chipVariants({ variant }), className)} {...props} />
}

Chip.displayName = 'Chip'

export { Chip }
