import { Link } from 'react-router-dom'
import { Trans } from '@lingui/react/macro'
import { buildPath } from '@/lib/build-path'
import { GAME_CATEGORY_PATH } from '@/config/paths'
import { cn } from '@vgw/tailwind-merger'
import { CustomIcon } from '@vgw/multibrand-fe-icon'

interface ViewAllProps {
  categorySlug: string
  className?: string
}

export const ViewAll = ({ categorySlug, className }: ViewAllProps) => {
  return (
    <Link
      to={buildPath(GAME_CATEGORY_PATH, { categoryName: categorySlug })}
      className={cn(
        [
          'flex',
          'items-center',
          'gap-1',

          'text-sm',
          'leading-5',
          'text-link-primary',
          'underline',

          'md:text-base',
          'lg:text-lg',
        ],
        className,
      )}
    >
      <span className="px-1.5 py-1">
        <Trans>View all</Trans>
      </span>{' '}
      <CustomIcon
        name="arrow-right"
        wrapperClassName={cn([
          'h-3.5',
          'w-3.5',
          'md:h-5',
          'md:w-5',
          'lg:h-6',
          'lg:w-6',
        ])}
      />
    </Link>
  )
}
