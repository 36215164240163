import { useQuery } from '@tanstack/react-query'
import {
  getStateOptions,
  PlayerTokenStateResponse,
  tokenType,
} from '@/apis/pam-player-tokens-api'
import { CoinsState } from './types'

function useCoinsStateQuery(isUserLoggedIn: boolean) {
  const { isLoading, data, error } = useQuery({
    ...getStateOptions(),
    refetchInterval: 5000,
    enabled: isUserLoggedIn,
  })

  return {
    isLoading,
    data: data && getCoinAmount(data),
    error,
  }
}

function getTokenAmount(
  tokens: PlayerTokenStateResponse['tokens'],
  type: tokenType,
) {
  return tokens?.find(({ tokenType }) => tokenType === type)?.amount
}

function getCoinAmount(data: PlayerTokenStateResponse): CoinsState {
  return {
    goldCoinsAmount: getTokenAmount(data.tokens, 'GC') || 0,
    sweepsCoinsAmount: getTokenAmount(data.tokens, 'SC') || 0,
  }
}

export { useCoinsStateQuery }
