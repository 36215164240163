import { createReactOidc } from 'oidc-spa/react'
import { loadBrandConfig } from '@/config/config'

const config = await loadBrandConfig()

export const { OidcProvider, useOidc, getOidc } = createReactOidc({
  issuerUri: `${config.keycloak.url}/${config.keycloak.realm}/`,
  clientId: config.keycloak.clientId,
  publicUrl: import.meta.env.BASE_URL || '/',
})
