import { HTMLAttributes, ReactNode } from 'react'

interface LabelProps extends HTMLAttributes<HTMLParagraphElement> {
  children: ReactNode
}

function Label({ children, ...rest }: LabelProps) {
  return (
    <p className="text-sm uppercase" {...rest}>
      {children}
    </p>
  )
}

export { Label }
