import { cva } from 'class-variance-authority'
import { HTMLAttributes, useContext } from 'react'
import { cn } from '@vgw/tailwind-merger'
import { FormSectionContext } from './form-section.context'

const labelVariants = cva('text-form-input-content-helper', {
  variants: {
    size: {
      sm: 'text-sm leading-5',
      md: 'text-base leading-6',
    },
  },
  defaultVariants: {
    size: 'sm',
  },
})

export interface HelperTextProps extends HTMLAttributes<HTMLSpanElement> {}

const HelperText = ({ className, ...props }: HelperTextProps) => {
  const { size } = useContext(FormSectionContext)

  return <span className={cn(labelVariants({ size, className }))} {...props} />
}

HelperText.displayName = 'HelperText'

export { HelperText }
