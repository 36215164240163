import { createContext, useContext } from 'react'

interface TextFieldContextValue {
  focused: boolean
  disabled: boolean
  hasValue: boolean
  value?: string
  defaultValue?: string
  error: boolean
  size: 'sm' | 'md'
  manuallyFocused: boolean
  setFocused: (_: boolean) => void
  setHasValue: (_: boolean) => void
}

export const TextFieldContext = createContext<TextFieldContextValue>({
  focused: false,
  disabled: false,
  hasValue: false,
  value: undefined,
  defaultValue: undefined,
  error: false,
  size: 'sm',
  manuallyFocused: false,
  setFocused: (_: boolean) => {},
  setHasValue: (_: boolean) => {},
})

export const useTextFieldContext = () => useContext(TextFieldContext)
