import { useState, forwardRef, HTMLAttributes, useEffect } from 'react'
import { cva, type VariantProps } from 'class-variance-authority'
import { cn } from '@vgw/tailwind-merger'

import { TextFieldContext } from './text-field.context'

const textFieldVariants = cva(
  cn(
    'relative',
    'w-full',

    'flex',
    'flex-col',

    'bg-form-input-surface',

    'rounded-radius-form',

    'text-form-input-content-base',

    'before:pointer-events-none',

    'before:absolute',
    'before:bottom-0',
    'before:left-0',
    'before:right-0',

    'before:border-b',
    'before:border-solid',

    'before:content-[""]',

    'before:transition-border-color',
    'before:duration-300',

    'overflow-hidden',
  ),
  {
    variants: {
      size: {
        sm: 'h-[46px] px-2 py-1.5 text-sm leading-5',
        md: 'h-14 px-3 py-2 text-base',
      },
    },
    defaultVariants: {
      size: 'sm',
    },
  },
)

export interface TextFieldProps
  extends HTMLAttributes<HTMLDivElement>,
    VariantProps<typeof textFieldVariants> {
  error?: boolean
  disabled?: boolean
  value?: string
  defaultValue?: string
  startIcon?: React.ReactNode
  endIcon?: React.ReactNode
  manuallyFocused?: boolean
}

export const TextField = forwardRef<HTMLDivElement, TextFieldProps>(
  (
    {
      className,
      size,
      error = false,
      value,
      children,
      disabled = false,
      startIcon,
      endIcon,
      manuallyFocused = false,
      defaultValue,
      ...props
    },
    ref,
  ) => {
    const [focused, setFocused] = useState(false)
    const [hasValue, setHasValue] = useState(!!value || !!defaultValue)

    useEffect(() => {
      if (!defaultValue) {
        setHasValue(!!value)
      }
    }, [value, defaultValue])

    return (
      <TextFieldContext.Provider
        value={{
          focused,
          hasValue,
          setFocused,
          setHasValue,
          disabled,
          value,
          defaultValue,
          error,
          manuallyFocused,
          size: size ?? 'sm',
        }}
      >
        <div
          data-testid="text-field"
          className={cn(textFieldVariants({ size, className }), {
            'bg-disabled before:border-disabled pointer-events-none before:border-b':
              disabled,
            'before:border-status-error before:border-b-2': error,
            'before:border-form-input-content-label-active before:border-b-2':
              focused && !error,
            'hover:before:border-form-input-border-hover': !focused && !error,
          })}
          ref={ref}
          {...props}
        >
          <div className="flex items-center">
            {startIcon && <div className="mr-2">{startIcon}</div>}
            <div className="w-full">{children}</div>
            {endIcon && <div className="ml-2">{endIcon}</div>}
          </div>
        </div>
      </TextFieldContext.Provider>
    )
  },
)

TextField.displayName = 'TextField'
