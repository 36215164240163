import { useGalleryQuery } from '@/features/game-api/use-gallery-query'
import { useLingui } from '@lingui/react/macro'

function useCategoryQuery(categoryName: string) {
  const { isLoading, data, error: lobbyError } = useGalleryQuery()
  const { t } = useLingui()

  const category = data?.categories.find(
    (category) => category.name === categoryName,
  )

  return {
    category,
    isLoading,
    error: lobbyError || !category ? t`An error has occurred` : undefined,
  }
}

export { useCategoryQuery }
