import { HTMLAttributes } from 'react'
import { cn } from '@vgw/tailwind-merger'

export interface ErrorMessageProps extends HTMLAttributes<HTMLDivElement> {}

const ErrorMessage = ({ className, ...props }: ErrorMessageProps) => (
  <div className={cn('text-status-error', className)} {...props} />
)

ErrorMessage.displayName = 'ErrorMessage'

export { ErrorMessage }
