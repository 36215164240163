import { useMemo } from 'react'
import { cn } from '@vgw/tailwind-merger'
import {
  OverlayedHeroBannerBase,
  OverlayedHeroBannerBaseCard,
} from './overlayed-hero-banner-base'

export const OverlayedHeroBannerSkeleton = () => {
  const slides = useMemo(
    () =>
      Array.from({ length: 4 }).map<OverlayedHeroBannerBaseCard>(() => ({
        MainElement: ({ className }) => (
          <div className={cn(className, 'bg-skeleton w-full animate-pulse')} />
        ),
        ThumbnailElement: ({ className }) => (
          <div className={cn(className, 'bg-skeleton w-full animate-pulse')} />
        ),
      })),
    [],
  )

  return (
    <OverlayedHeroBannerBase
      className="pointer-events-none"
      cards={slides}
      onCardEntersViewport={() => {}}
      autoPlayDelay={false}
    />
  )
}
