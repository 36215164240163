import { createContext, ReactNode, useState } from 'react'

interface SelectedCoinTypeContextProviderProps {
  children: ReactNode
}

export type CoinType = 'GC' | 'SC'

interface ContextValue {
  selectedCoinType: CoinType
  setSelectedCoinType: (_coinType: CoinType) => void
}

// eslint-disable-next-line react-refresh/only-export-components
export const SelectedCoinTypeContext = createContext<ContextValue>({
  selectedCoinType: 'GC',
  setSelectedCoinType: () => {},
})

export function SelectedCoinTypeContextProvider({
  children,
}: SelectedCoinTypeContextProviderProps) {
  const [selectedCoinType, setSelectedCoinType] = useState<CoinType>('GC')

  return (
    <SelectedCoinTypeContext.Provider
      value={{
        selectedCoinType: selectedCoinType,
        setSelectedCoinType: setSelectedCoinType,
      }}
    >
      {children}
    </SelectedCoinTypeContext.Provider>
  )
}
