import { createContext } from 'react'
import { SelectSize } from './types'

interface ContextValue {
  size: SelectSize
  value?: string
  error: boolean
  focused: boolean
  disabled: boolean
  hasLabel: boolean
  showValue: boolean
  placeholder?: string
  onSetFocused?: (_: boolean) => void
  onSetValue?: (_: string) => void
}

export const SelectContext = createContext<ContextValue>({
  size: 'sm',
  error: false,
  focused: false,
  disabled: false,
  hasLabel: true,
  showValue: true,
})
