import { ReactNode } from 'react'

interface FeatureProps {
  isVisible?: boolean
  children: ReactNode
}

function Feature({ children, isVisible = true }: FeatureProps) {
  if (!isVisible) return null

  return (
    <div className="flex h-14 flex-col items-center justify-between py-0.5">
      {children}
    </div>
  )
}

export { Feature }
